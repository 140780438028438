import i18n from "../plugins/vue-i18n";
import Swal from "sweetalert";
import {alertMessage, errorMessage, pageRedirect, pageReload, serviceHeaderBuilder, warningMessage} from "./helper";
import {initError} from "./handleError";
import {formValidation} from "../../../../assets/plugins/formvalidation/dist/amd";
import {openParentModal} from "./modal";
import {loadToggleUtil} from "./load-util";

const {_elementUtil} = require("./element-util");

export const serviceQueryBuilder = (element, dataType) => {
    let query = {
        element: element,
        dataType: typeof dataType !== 'undefined' ? dataType.toLowerCase() : 'json',
        form: null,
        requestData: {},
    };

    query.set = {
        form: function () {
            if (_elementUtil.getType(query.element) === 'form') {
                query.form = query.element;
            } else {
                query.form = query.element.parents('form').first();
            }
        },
        init: function () {
            query.set.form();
            return true;
        }
    };

    query.request = {
        check: function (checkElement) {
            if (checkElement.prop('type') === "checkbox") {
                if (typeof checkElement.attr("data-ignore-unchecked") !== 'undefined' &&
                    checkElement.attr("data-ignore-unchecked") === 'true' &&
                    !checkElement.is(':checked')) {
                    return false;
                }
            }

            if (checkElement.prop('type') === "radio") {
                if (typeof checkElement.attr("data-set-null") !== 'undefined' &&
                    checkElement.attr("data-set-null") === 'true') {
                    return true;
                } else {
                    if (!checkElement.is(':checked')) {
                        return false;
                    }
                }
            }

            return !(typeof checkElement.attr("data-ignore-empty-value") !== 'undefined' &&
                checkElement.attr("data-ignore-empty-value") === 'true' &&
                (checkElement.val() === null || checkElement.val().trim() === ""));
        },
        getKey: function (key) {
            if (key.substr(-2) === '[]') {
                return {
                    key: key.substr(0, key.length - 2),
                    isArray: true
                }
            } else {
                return {
                    key: key,
                    isArray: false
                }
            }
        },
        create: function (keys, index, value, variable, valueType) {
            if (index === keys.length - 1) {
                if (valueType === "subKey") {
                    for (let item in value) {
                        if (value[item].toString().startsWith("#")) {
                            if ($(value[item].toString()).length > 0) {
                                value[item] = query.request.value($(value[item].toString()));
                            }
                        }
                    }
                    let getKey = query.request.getKey(keys[index]);
                    variable[getKey.key] = value;
                } else if (valueType === "default") {
                    if (typeof variable[keys[index]] === 'undefined') {
                        if (value !== null && typeof value === 'object') {
                            let getKey = query.request.getKey(keys[index]);
                            if (value instanceof Array) {
                                variable[getKey.key] = value;
                            } else {
                                variable[getKey.key] = [];
                                variable[getKey.key].push(value);
                            }
                        } else {
                            let getKey = query.request.getKey(keys[index]);
                            if (getKey.isArray) {
                                variable[getKey.key] = [];
                                if (value !== null) {
                                    variable[getKey.key].push(value);
                                }

                            } else {
                                variable[getKey.key] = value;
                            }
                        }
                    } else {
                        let getKey = query.request.getKey(keys[index]);
                        let newValue = [];
                        if (variable[getKey.key] instanceof Array) {
                            variable[getKey.key].push(value);
                        } else {
                            newValue = variable[getKey.key];
                            variable[getKey.key] = [];
                            variable[getKey.key].push(newValue);
                            variable[getKey.key].push(value);
                        }
                    }
                }

                return variable;
            } else {
                if (typeof variable[keys[index]] === 'undefined') {
                    variable[keys[index]] = {};
                }

                variable[keys[index]] = query.request.create(
                    keys,
                    index + 1,
                    value,
                    variable[keys[index]],
                    valueType);
                return variable;
            }
        },
        value: function (valueElement) {
            if (typeof valueElement.attr('data-method-sub-key') !== 'undefined') {
                let value = valueElement.val();
                if (valueElement.prop('type') === "checkbox") {
                    value = query.request.valueTypeCheckBox(valueElement);
                }

                let newValue = JSON.parse(valueElement.attr('data-method-sub-key').replace("#value#", value).replace(/'/gi, '"'));
                if (query.dataType === "query" && Array.isArray(newValue) && newValue.length === 0) {
                    newValue = null;
                }
                return newValue;
            } else if (valueElement.prop('type') === "checkbox") {
                return query.request.valueTypeCheckBox(valueElement);
            } else if (valueElement.prop('nodeName') === "SELECT" && typeof valueElement.attr('join') !== 'undefined') {
                return valueElement.val().join(valueElement.attr('join'));
            } else if (valueElement.prop('nodeName') === "SELECT" && typeof valueElement.attr('data-selectable') !== 'undefined') {
                return JSON.parse(valueElement.attr("data-selectable-value").replace(/'/gi, '"'));
            } else if (valueElement.prop('type') === "radio") {
                return query.request.valueTypeRadio(valueElement);
            } else {
                return query.request.valueType(valueElement);
            }
        },
        valueTypeCheckBox: function (valueTypeElement) {
            let value;
            if (valueTypeElement.is(':checked')) {
                if (typeof valueTypeElement.attr("value") !== 'undefined') {
                    value = query.request.valueType(valueTypeElement);
                } else {
                    value = true;
                }
            } else {
                if (typeof valueTypeElement.attr("value") === 'undefined') {
                    value = false;
                }
            }

            return value;
        },
        valueTypeRadio: function (valueTypeElement) {
            let value;
            let name = valueTypeElement.attr('name');
            let isChecked = false;
            let isValueExist = false;
            query.form.find('input[type=radio][name=' + name + ']').each(function () {
                if ($(this).is(':checked')) {
                    isChecked = true;
                    if (typeof $(this).attr("value") !== 'undefined') {
                        value = query.request.valueType($(this));
                    } else {
                        value = true;
                    }
                }
                if (typeof $(this).attr("value") !== 'undefined') {
                    isValueExist = true;
                }
            });

            if (isChecked === false && isValueExist === false) {
                value = false;
            } else if (isChecked === false && isValueExist === true) {
                if (query.request.valueIsNull(valueTypeElement)) {
                    value = null;
                }
            }

            return value;
        },
        valueType: function (valueTypeElement) {
            let value = valueTypeElement.val();
            let valueIsNull = false;
            if (query.request.valueIsNull(valueTypeElement)) {
                if (typeof value === 'object') {
                    if (value == null) {
                        value = null;
                        valueIsNull = true;
                    } else if (value.length === 0) {
                        value = null;
                        valueIsNull = true;
                    }
                } else {
                    if (valueTypeElement.prop('type') === "radio") {
                        if (!valueTypeElement.is(':checked')) {
                            value = null;
                            valueIsNull = true;
                        }
                    } else {
                        if (value.trim() === '') {
                            value = null;
                            valueIsNull = true;
                        }
                    }
                }
            }
            if (!valueIsNull) {
                if (query.request.valueIsNumber(valueTypeElement)) {
                    if (typeof value === 'object' && value instanceof Array) {
                        for (let v in value) {
                            value[v] = Number(value[v].replace(/,/gi, ''));
                        }
                    } else {
                        value = Number(value.replace(/,/gi, ''));
                    }

                } else if (query.request.valueIsBoolean(valueTypeElement)) {
                    value = value === 'true' || value === '1';
                } else if (query.request.valueIsDate(valueTypeElement)) {
                    if (value.replace(/ /gi, '') !== "") {
                        let spl = value.split("/");
                        let day = spl[0];
                        let month = spl[1];
                        let year = spl[2];
                        value = year + "-" + month + "-" + day;
                    }
                } else if (query.request.valueIsAttr(valueTypeElement)) {
                    value = valueTypeElement.attr('data-method-value');
                } else if (query.request.valueIsPhone(valueTypeElement)) {
                    value = valueTypeElement.attr('data-memory-pcode') + value;
                }
            }

            return value;
        },
        valueIsNull: function (valueTypeElement) {
            return typeof valueTypeElement.attr('data-set-null') !== 'undefined' && valueTypeElement.attr('data-set-null') === 'true';
        },
        valueIsNumber: function (valueTypeElement) {
            if (typeof valueTypeElement.attr('data-type') !== 'undefined') {
                let type = valueTypeElement.attr('data-type');
                if (type === 'integer' || type === 'number' || type === 'decimal' || type === 'money') {
                    return true;
                }
            }
            return false;
        },
        valueIsBoolean: function (valueTypeElement) {
            if (typeof valueTypeElement.attr('data-type') !== 'undefined' && valueTypeElement.attr('data-type') === 'boolean') {
                return true;
            } else {
                return false;
            }
        },
        valueIsDate: function (valueTypeElement) {
            if (typeof valueTypeElement.attr('data-type') !== 'undefined' && valueTypeElement.attr('data-type') === 'date') {
                return true;
            } else {
                return false;
            }
        },
        valueIsAttr: function (valueTypeElement) {
            if (typeof valueTypeElement.attr('data-type') !== 'undefined' && valueTypeElement.attr('data-type') === 'attr') {
                return true;
            } else {
                return false;
            }
        },
        valueIsSubKey: function (subValueElement) {
            if (typeof subValueElement.attr('data-method-sub-key') !== 'undefined') {
                return 'subKey';
            } else {
                return 'default';
            }
        },
        valueIsPhone: function (valueTypeElement) {
            if (typeof valueTypeElement.attr('data-type') !== 'undefined') {
                let type = valueTypeElement.attr('data-type');
                if (type === 'phone') {
                    return true;
                }
            }
            return false;
        },
        split: function (key) {
            let arrayKeys = [];
            let currPos = 0,
                prevPos = -1;
            while ((currPos = key.indexOf('.', currPos + 1)) !== -1) {
                if (key[currPos - 1] === "\\") {
                    continue;
                }
                let recollect = key.substr(prevPos + 1, currPos - prevPos - 1).replace(/\\./g, ".");
                prevPos = currPos;
                arrayKeys.push(recollect);
            }
            let recollect = key.substr(prevPos + 1).replace(/\\./g, ".");
            arrayKeys.push(recollect);
            return arrayKeys;
        },
        data: function () {
            query.form.find("[data-method-key]").each(function () {
                let keys = query.request.split($(this).attr("data-method-key"));
                if (query.request.check($(this))) {
                    query.requestData = query.request.create(
                        keys,
                        0,
                        query.request.value($(this)),
                        query.requestData,
                        query.request.valueIsSubKey($(this))
                    );
                }
            });

            if (query.dataType === "json") {
                return query.requestData;
            } else if (query.dataType === "query") {
                return decodeURIComponent($.param(query.requestData));
            }
        }
    };

    if (query.set.init()) {
        return {process: true, query: query.request.data()};
    }

    return {process: false, query: null};
};

export const serviceUrlBuilder = (url, title, html) => {
    window.history.replaceState(
        typeof html !== 'undefined' && html !== null ? html : {},
        typeof title !== 'undefined' && title !== null ? title : "",
        typeof url !== 'undefined' && url !== null ? url : null
    );
};

export const serviceUtil = function (element) {
    let service = {
        element: element,
        dataMethod: null,
        methodType: null,
        requestData: {},
        form: null,
        url: null,
        responseData: null,
        submitButton: null,
        dataReload: null,
        dataRedirect: null,
        dataCallBefore: null,
        dataCallBack: null,
        dataCallBackGeneric: null,
        elementTable: null,
        fromTo: null,
        parentRow: null,
        parentTable: null,
        processType: null,
    };

    service.set = {
        isModal: function () {
            if (service.element.parents(".modal").length > 0) {
                return "#" + service.element.parents(".modal").attr("id");
            }
            return null;
        },
        dataMethod: function () {
            service.dataMethod = service.element.attr("data-method");
        },
        form: function () {
            service.form = service.element.parents('form').first();
        },
        url: function () {
            service.url = service.element.attr("data-method-url");
        },
        submitButton: function () {
            service.submitButton = service.element;
        },
        dataReload: function () {
            service.dataReload = typeof service.element.attr("data-reload") !== 'undefined' ? service.element.attr("data-reload") : null;
        },
        elementTable: function () {
            if (typeof service.element.attr("data-table-id") !== 'undefined') {
                service.elementTable = service.element.attr("data-table-id");
            } else if (typeof service.element.parents('table') !== 'undefined' && typeof service.element.parents('table').first().attr("data-table-type") !== 'undefined' && service.element.parents('table').first().attr("data-table-type") === 'datatable') {
                service.elementTable = "#" + service.element.parents('table').first().attr("id");
            } else {
                service.elementTable = null;
            }
        },
        fromTo: function () {
            service.fromTo = typeof service.element.attr("data-method-from") !== 'undefined' ? service.element.attr("data-method-from") : null;
        },
        responseData: function () {
            service.responseData = service.element.attr("data-method-response-data");
        },
        parentTable: function () {
            service.parentTable = service.element.parents('table').first();
        },
        parentRow: function () {
            service.parentRow = service.element.parents('tr').first();
        },
        processType: function () {
            service.processType = typeof service.element.attr("data-method-name") !== 'undefined' ? service.element.attr("data-method-name") : service.dataMethod;
        },
        dataRedirect: function () {
            service.dataRedirect = typeof service.element.attr("data-redirect") !== 'undefined' ? service.element.attr("data-redirect") : null;
        },
        dataCallBefore: function () {
            service.dataCallBefore = typeof service.element.attr("data-callbefore") !== 'undefined' && service.element.attr("data-callbefore") !== null && service.element.attr("data-callbefore") !== "" ? service.element.attr("data-callbefore") : null;
        },
        dataCallBack: function () {
            service.dataCallBack = typeof service.element.attr("data-callback") !== 'undefined' && service.element.attr("data-callback") !== null ? service.element.attr("data-callback") : null;
        },
        dataCallBackGeneric: function () {
            service.dataCallBackGeneric = typeof service.element.attr("data-callback-generic") !== 'undefined' && service.element.attr("data-callback-generic") !== null ? service.element.attr("data-callback-generic") : null;
        },
        methodType: function () {
            service.methodType = typeof service.element.attr("data-method-type") !== 'undefined' ? service.element.attr("data-method-type") : null;
        },
        variable: function () {
            service.set.submitButton();
            service.button.disable();

            service.set.dataMethod();
            service.set.form();
            service.set.url();
            service.set.dataReload();
            service.set.dataRedirect();
            service.set.dataCallBefore();
            service.set.dataCallBack();
            service.set.dataCallBackGeneric();
            service.set.elementTable();
            service.set.fromTo();
            service.set.responseData();
            service.set.parentTable();
            service.set.parentRow();
            service.set.processType();
            service.set.methodType();


        }
    };

    service.confirm = {
        title: function () {
            return typeof service.element.attr('data-confirm-' + service.processType + '-title') !== 'undefined' ? service.element.attr('data-confirm-' + service.processType + '-title') : service.parentTable.attr('data-confirm-' + service.processType + '-title');
        },
        text: function () {
            return typeof service.element.attr('data-confirm-' + service.processType + '-text') !== 'undefined' ? service.element.attr('data-confirm-' + service.processType + '-text') : service.parentTable.attr('data-confirm-' + service.processType + '-text');
        },
        type: function () {
            return "warning";
        },
        showCancelButton: function () {
            return true;
        },
        confirmButtonText: function () {
            return i18n.messages.yes;
        },
        isActive: function () {
            if (typeof service.element.attr('data-confirm-' + service.processType + '-text') !== 'undefined') {
                return true;
            } else if (typeof service.parentTable.attr('data-confirm-' + service.processType + '-text') !== 'undefined') {
                return true;
            }
            return false;
        },
        check: function () {
            if (service.confirm.isActive()) {
                service.button.enable();
                Swal.fire({
                    title: service.confirm.title(),
                    text: service.confirm.text(),
                    type: service.confirm.type(),
                    showCancelButton: service.confirm.showCancelButton(),
                    confirmButtonText: service.confirm.confirmButtonText(),
                }).then(function (result) {
                    if (result.value) {
                        service.request.send();
                    }
                });

                return false;
            }

            return true;
        }
    };

    service.formControl = {
        validation: function () {
            if (service.form.length === 0) {
                return true;
            } else {
                if (formValidation(service.form)) {
                    return true;
                } else {
                    service.button.enable();
                    return false;
                }
            }
        }
    };

    service.button = {
        disable: function () {
            let loaderClass = 'm-loader m-loader--light m-loader--left';
            let bc = service.submitButton.css("background-color");
            if (bc === 'rgb(255, 255, 255)' || bc === 'white' || bc === '#FFF' || bc === '#FFFFFF') {
                loaderClass = 'm-loader m-loader--danger m-loader--left';
            }
            if (service.submitButton.hasClass('inline-edit-only-icon')) {
                loaderClass = 'm-loader m-loader--light';
                if (bc === 'rgb(255, 255, 255)' || bc === 'white' || bc === '#FFF' || bc === '#FFFFFF') {
                    loaderClass = 'm-loader m-loader--danger';
                }
            }
            service.submitButton.attr("disabled", true).addClass(loaderClass);

            if (typeof service.submitButton.attr("data-method-loader-element") !== 'undefined') {
                $(service.submitButton.attr("data-method-loader-element")).html('<div class="m-20px text-center"><div class="m-spinner m-spinner--brand m-spinner--sm"></div>' +
                    '<div class="m-spinner m-spinner--primary m-spinner--sm"></div>' +
                    '<div class="m-spinner m-spinner--success m-spinner--sm"></div>' +
                    '<div class="m-spinner m-spinner--info m-spinner--sm"></div>' +
                    '<div class="m-spinner m-spinner--warning m-spinner--sm"></div>' +
                    '<div class="m-spinner m-spinner--danger m-spinner--sm"></div></div>');
            }
        },
        enable: function () {
            service.submitButton.attr("disabled", false).removeClass('m-loader m-loader--light m-loader--left m-loader--danger');
        },
        isStop: function () {
            if (typeof service.submitButton.attr("data-stop-action-message") !== 'undefined') {
                errorMessage(service.submitButton.attr("data-stop-action-message"));
                service.button.enable();
                return true;
            }

            return false;
        }
    };

    service.request = {
        data: function () {
            let query = serviceQueryBuilder(service.element, service.form !== null && service.form.attr('enctype') !== null && service.form.attr('enctype') === 'multipart/form-data' ? 'query' : 'json');
            service.requestData = query.query;
            if (service.form.attr('enctype') !== null && service.form.attr('enctype') === 'multipart/form-data') {
                let fd = new FormData();
                let fileContainer = service.form.find('[data-file-container=true]');
                let fileContainerDataKey = fileContainer.attr('data-method-key');
                let arrayQuery = query.query.split('&');
                let file = fileContainer.prop('files')[0];
                fd.append(fileContainerDataKey, file);
                $.each(arrayQuery, function (index, query) {
                    let spl = query.split('=');
                    if (fileContainerDataKey !== spl[0]) {
                        fd.append(spl[0], spl[1]);
                    }
                });
                service.requestData = fd;
            }
        },
        ajax: {
            method: function () {
                if (service.methodType != null) {
                    return service.methodType;
                }

                switch (service.dataMethod) {
                    case 'edit':
                        return 'PUT';
                    case 'delete':
                        return 'DELETE';
                    case 'get':
                        return 'GET';
                    default:
                        return 'POST';
                }
            },
            dataType: function () {
                return 'json';
            },
            data: function () {
                if (service.form !== null && service.form.attr('enctype') !== null && service.form.attr('enctype') === 'multipart/form-data') {
                    return service.requestData;
                } else {
                    return JSON.stringify(service.requestData);
                }

            },
            url: function () {
                let regex = new RegExp(/##(.*?)##/gm);
                let replaceData = [];
                let m;
                while ((m = regex.exec(service.url)) !== null) {
                    if (m.index === regex.lastIndex) {
                        regex.lastIndex++;
                    }
                    for (let i1 = 0; i1 < m.length / 2; i1++) {
                        replaceData.push({value: m[i1], key: m[i1 + 1]});
                    }
                }
                if (replaceData.length > 0) {
                    for (let i = 0; i < replaceData.length; i++) {
                        let rpc = "\#\#" + replaceData[i].key + "\#\#";
                        let val = service.form.find("#" + replaceData[i].key).val();
                        service.url = service.url.replace(rpc, val);
                    }
                }
                return service.url;
            },
        },
        send: function () {
            service.button.disable();
            service.request.data();
            $.ajax({
                type: service.request.ajax.method(),
                dataType: service.request.ajax.dataType(),
                url: service.request.ajax.url(),
                data: service.request.ajax.data(),
                beforeSend: serviceHeaderBuilder,
                processData: false,
                contentType: false,
                success: function (response, textStatus, xhr) {
                    service.response.init(response, textStatus, xhr);
                },
                error: function (xhr) {
                    initError(xhr);
                }
            });
        },
    };

    service.response = {
        message: function () {
            return typeof service.element.attr('data-' + service.processType + '-success-message') !== 'undefined' ? service.element.attr('data-' + service.processType + '-success-message') : service.parentTable.attr('data-' + service.processType + '-success-message');
        },
        alertMessage: function () {
            let message = service.response.message();
            if (typeof message !== 'undefined' && message !== "") {
                alertMessage(message);
            }
        },
        alertMessages: function (response) {
            let timmer = 0;
            if (typeof response.data !== 'undefined' && typeof response.data.successMessages !== 'undefined') {
                $.each(response.data.successMessages, function (index, message) {
                    setTimeout(function () {
                        alertMessage(message);
                    }, timmer);
                    timmer += 150;
                });
            }
            if (typeof response.data !== 'undefined' && typeof response.data.failMessages !== 'undefined') {
                $.each(response.data.failMessages, function (index, message) {
                    setTimeout(function () {
                        warningMessage(message);
                    }, timmer);
                    timmer += 150;
                });
            }
        },
        pageReload: function () {
            pageReload(service.response.message());
        },
        pageRedirect: function () {
            pageRedirect(service.dataRedirect, service.response.message());
        },
        modalReload: function (isShow) {
            if (typeof service.element.parents(".modal") !== 'undefined') {
                let modal = service.element.parents(".modal");
                openParentModal("#" + modal.attr("id"), isShow);
            }
        },
        parentModalReload: function (isShow) {
            if (typeof service.element.parents(".modal") !== 'undefined' && service.element.parents(".modal").length > 0) {
                let modal = service.element.parents(".modal");
                let parentModal = typeof modal.attr("data-modal-parent-id") !== 'undefined' ? $("#" + modal.attr("data-modal-parent-id")) : null;
                if (parentModal != null) {
                    openParentModal("#" + parentModal.attr("id"), isShow);
                }
            }
            if (typeof service.element.parents(".popover") !== 'undefined' && service.element.parents(".popover").length > 0) {
                let popover = service.element.parents(".popover");
                let parentModal = typeof popover.attr("data-modal-parent-id") !== 'undefined' ? $("#" + popover.attr("data-modal-parent-id")) : null;
                if (parentModal != null) {
                    openParentModal("#" + parentModal.attr("id"), isShow);
                }
            }
        },
        parentParentModalReload: function (isShow) {
            if (typeof service.element.parents(".modal") !== 'undefined' && service.element.parents(".modal").length > 0) {
                let modal = service.element.parents(".modal");
                let parentModal = typeof modal.attr("data-modal-parent-id") !== 'undefined' ? $("#" + modal.attr("data-modal-parent-id")) : null;
                if (parentModal != null) {
                    let parentParentModal = typeof parentModal.attr("data-modal-parent-id") !== 'undefined' ? $("#" + parentModal.attr("data-modal-parent-id")) : null;
                    if (parentParentModal != null) {
                        openParentModal("#" + parentParentModal.attr("id"), isShow);
                    }
                }
            }
            if (typeof service.element.parents(".popover") !== 'undefined' && service.element.parents(".popover").length > 0) {
                let popover = service.element.parents(".popover");
                let parentModal = typeof popover.attr("data-modal-parent-id") !== 'undefined' ? $("#" + popover.attr("data-modal-parent-id")) : null;
                if (parentModal != null) {
                    let parentParentModal = typeof parentModal.attr("data-modal-parent-id") !== 'undefined' ? $("#" + parentModal.attr("data-modal-parent-id")) : null;
                    if (parentParentModal != null) {
                        openParentModal("#" + parentParentModal.attr("id"), isShow);
                    }
                }
            }
        },
        parentToggleReload: function () {
            if (typeof service.element.parents(".modal") !== 'undefined' && service.element.parents(".modal").length > 0) {
                let modal = service.element.parents(".modal");
                let parentToggle = typeof modal.attr("data-toggle-parent-id") !== 'undefined' ? $("#" + modal.attr("data-toggle-parent-id")) : null;
                if (parentToggle != null) {
                    loadToggleUtil("#" + parentToggle.attr("id"));
                }
            }
        },
        modalHide: function () {
            if (typeof service.element.parents(".modal") !== 'undefined') {
                let modal = service.element.parents(".modal");
                modal.modal('hide');
            }
        },
        modalParentHide: function () {
            if (typeof service.element.parents(".modal") !== 'undefined') {
                let modal = service.element.parents(".modal");
                let parentModal = typeof modal.attr("data-modal-parent-id") !== 'undefined' ? $("#" + modal.attr("data-modal-parent-id")) : null;
                if (parentModal != null) {
                    parentModal.modal('hide');
                }

            }
        },
        popoverHide: function () {
            if (typeof service.element.parents(".popover") !== 'undefined') {
                let popover = service.element.parents(".popover");
                popover.popover('hide');
            }
        },
        getDataKey: function (response) {
            for (let key in response.data) {
                service.responseData = key;
                break;
            }

            return true;
        },
        dataTable: {
            noDataMessage: function () {
                let colLength = $(service.elementTable + ">thead>tr>th").length;
                return $("<tr/>", {
                    class: 'odd'
                }).append($("<td/>", {
                    html: i18n.messages.noDataAvailableInTable,
                    colSpan: colLength,
                    class: 'dataTables_empty',
                }));
            },
            reload: function () {
                //window.allDatableObj[service.elementTable].table.ajax.reload();
                window.allDatableObj[service.elementTable].table._fnAjaxUpdate();
            },
            getAttributes: function (node) {
                let attrs = "";
                $.each(node[0].attributes, function (index, attribute) {
                    attrs += " " + attribute.name + '="' + attribute.value + '"';
                });
                return attrs;
            },
            value: function (data, key, index) {
                if (data === null) {
                    return "";
                } else if (typeof key[index] === 'undefined') {
                    return "";
                } else if (key[index] === null) {
                    return "";
                } else if (typeof data[key[index]] === 'undefined') {
                    return "";
                } else if (data[key[index]] === null) {
                    return "";
                } else if (typeof data[key[index]] === "object") {
                    return service.response.dataTable.value(data[key[index]], key, index + 1);
                } else {
                    return data[key[index]];
                }
            },
            renderHtml: function (data) {
                let wrapper = service.elementTable + '_wrapper';
                let footerReference = service.elementTable;
                if ($(wrapper).length > 0) {
                    footerReference = wrapper + ' .dataTables_scrollFoot table';
                }

                let regex = new RegExp(/##(.*?)##/gm);
                let rowId = $(footerReference + ">tfoot>tr#reference").attr("data-table-row-id");
                if (service.dataMethod === "delete") {
                    return {
                        rowId: rowId,
                        html: null
                    };
                }

                let id = service.response.dataTable.getRowId(data, rowId);
                let newHtml = '<tr id="' + id + '" role="row">';
                $(footerReference + ">tfoot>tr#reference>td").each(function (index) {
                    let html = $(this).html().replace("data-img-src", "src");
                    let attrs = service.response.dataTable.getAttributes($(this));
                    let replaceData = [];
                    let m;
                    let tdTitle = typeof $(service.elementTable + ">thead>tr>th:eq(" + index + ")") !== 'undefined' ? $(service.elementTable + ">thead>tr>th:eq(" + index + ")").html() : "";
                    while ((m = regex.exec(html)) !== null) {
                        if (m.index === regex.lastIndex) {
                            regex.lastIndex++;
                        }
                        for (let i1 = 0; i1 < m.length / 2; i1++) {
                            replaceData.push({value: m[i1], key: m[i1 + 1]});
                        }
                    }
                    replaceData.forEach(function (field, index) {
                        html = html.replace(field.value, service.response.dataTable.value(data, field.key.split("."), 0));
                    });
                    newHtml += "<td" + attrs + " data-rt-label='" + tdTitle + "'>" + html + "</td>";
                });
                newHtml += '</tr>';

                return {
                    rowId: rowId,
                    html: newHtml
                };
            },
            process: function (response, render) {
                if (service.dataMethod === "add" || service.dataMethod === "copy") {
                    if ($(service.elementTable + ">tbody>tr>td[class=dataTables_empty]").length > 0) {
                        $(service.elementTable + ">tbody>tr>td[class=dataTables_empty]").parent("tr").remove();
                        $(service.elementTable + " tbody").append(render.html);
                    } else {
                        $(service.elementTable + ">tbody>tr:first").before(render.html);
                    }
                } else if (service.dataMethod === "delete") {
                    service.response.getDataKey(response);
                    let id = service.response.dataTable.getRowId(response.data[service.responseData], render.rowId);
                    $(service.elementTable + ">tbody>tr#" + id).remove();
                    if ($(service.elementTable + ">tbody>tr").length === 0) {
                        $(service.elementTable + " tbody").append(service.response.dataTable.noDataMessage());
                    }
                } else if (typeof service.element.attr('data-table-reload') !== 'undefined') {
                    $(service.elementTable + ">tbody>tr").remove();
                    if (render.html === "") {
                        $(service.elementTable + " tbody").append(service.response.dataTable.noDataMessage());
                    } else {
                        $(service.elementTable + " tbody").append(render.html);
                    }
                } else {
                    let id = service.response.dataTable.getRowId(response.data[service.responseData], render.rowId);
                    $(service.elementTable + ">tbody>tr#" + id).replaceWith(render.html);
                }
            },
            getRowId: function (data, index) {
                let id = null;
                if (typeof index !== 'undefined') {
                    let split = index.split('.');
                    if (split.length === 1) {
                        id = data[index];
                    } else if (split.length === 2) {
                        id = data[split[0]][split[1]];
                    } else if (split.length === 3) {
                        id = data[split[0]][split[1]][split[2]];
                    }
                }
                return id;
            },
            init: function (response) {
                if (service.elementTable != null) {
                    $(service.elementTable).attr("data-table-process", service.dataMethod, service.dataReload);
                    if (service.dataReload === "table") {
                        service.response.dataTable.reload();
                    } else if (service.dataReload === "table-all") {
                        if (typeof response.data === 'undefined' &&
                            typeof service.element.attr('data-method-response-render') !== 'undefined' &&
                            service.element.attr('data-method-response-render') === 'true') {
                            let buffer = response;
                            response = {
                                data: {}
                            }
                            response.data[service.responseData] = buffer;
                        }
                        let newHtml = {html: ""};
                        $.each(response.data[service.responseData], function (index, data) {
                            let render = service.response.dataTable.renderHtml(data);
                            newHtml.html += render.html;
                        });
                        service.response.dataTable.process(response, newHtml);
                    } else {
                        let render = service.response.dataTable.renderHtml(response.data[service.responseData]);
                        service.response.dataTable.process(response, render);
                    }
                }
            }
        },
        parentTable: {
            isRemove: function () {
                return service.dataMethod === "delete" || service.dataMethod === "get";
            },
            process: function () {
                if (service.response.parentTable.isRemove()) {
                    service.parentRow.remove();
                }
            },
            init: function (response) {
                if (service.parentTable != null) {
                    service.response.parentTable.process(response, null);
                }
            }
        },
        reload: {
            page: function () {
                service.response.pageReload();
            },
            modal: function () {
                service.response.modalReload(true);
                service.response.alertMessage();
                service.button.enable();
            },
            modalParentModal: function () {
                service.response.parentModalReload(false);
                service.response.modalReload(true);
                service.response.alertMessage();
                service.button.enable();
            },
            modalParentParentModal: function () {
                service.response.modalHide();
                service.response.parentModalReload(true);
                service.response.parentParentModalReload(false);
                service.response.alertMessage();
                service.button.enable();
            },
            parentModal: function () {
                service.response.modalHide();
                service.response.popoverHide();
                service.response.parentModalReload(true);
                service.response.alertMessage();
                service.button.enable();
            },
            parentParentModal: function () {
                service.response.modalHide();
                service.response.modalParentHide();
                service.response.popoverHide();
                service.response.parentParentModalReload(true);
                service.response.alertMessage();
                service.button.enable();
            },
            parentToggle: function () {
                service.response.modalHide();
                service.response.popoverHide();
                service.response.parentToggleReload();
                service.response.alertMessage();
                service.button.enable();
            },
            emptyMessage: function () {
                service.response.alertMessage();
                service.button.enable();
            },
            default: function (response) {
                if (service.elementTable != null) {
                    service.response.dataTable.init(response);
                } else {
                    service.response.parentTable.init(response);
                }
                service.response.modalHide();
                service.response.popoverHide();
                service.response.alertMessage();
                service.button.enable();
            }
        },
        redirect: {
            responseData: function (response) {
                if (typeof service.element.attr('data-method-response-data') !== 'undefined') {
                    if (response != null) {
                        if (typeof response.data !== 'undefined') {
                            if (typeof response.data[service.element.attr('data-method-response-data')] !== 'undefined') {
                                return response.data[service.element.attr('data-method-response-data')];
                            }
                        }
                    }
                }

                return null;
            },
            renderUrl: function (data) {
                let regex = new RegExp(/##(.*?)##/gm);
                let newUrl = service.dataRedirect;
                let replaceData = [];
                let m;
                while ((m = regex.exec(service.dataRedirect)) !== null) {
                    if (m.index === regex.lastIndex) {
                        regex.lastIndex++;
                    }
                    for (let i1 = 0; i1 < m.length / 2; i1++) {
                        replaceData.push({value: m[i1], key: m[i1 + 1]});
                    }
                }

                replaceData.forEach(function (field, index) {
                    newUrl = newUrl.replace(field.value, service.response.dataTable.value(data, field.key.split("."), 0));
                });

                service.dataRedirect = newUrl;
                service.response.pageRedirect();
            },
            page: function (response) {
                let responseData = service.response.redirect.responseData(response);
                if (responseData != null) {
                    service.response.redirect.renderUrl(responseData);
                } else {
                    service.response.pageRedirect();
                }
            },
        },
        modalFromTo: function () {
            if (typeof service.element.parents(".modal") !== 'undefined') {
                let modal = service.element.parents(".modal");
                if (typeof modal.attr("data-modal-parent-id") !== 'undefined' && typeof modal.attr("data-modal-from") !== 'undefined') {
                    let parentModal = $("#" + modal.attr("data-modal-parent-id"));
                    let from = modal.attr("data-modal-from");
                    if (from === 'addNew') {
                        let element = $("#" + modal.attr("data-modal-parent-id") + " #" + modal.attr("data-modal-from-id"));
                        modal.modal('hide');
                        parentModal.modal('show');
                        element.trigger("addNew");
                        return true;
                    }
                }
            }

            return false;
        },
        callBefore: function () {
            if (service.dataCallBefore !== null) {
                let rtn = window[service.dataCallBefore](service.element);
                if (rtn === false) {
                    service.button.enable();
                }
                return rtn;
            }
            return true;
        },
        callBack: function (response) {
            service.button.enable();
            service.response.modalHide();
            service.response.popoverHide();
            service.response.alertMessage();
            service.button.enable();
            window[service.dataCallBack](response.data[service.responseData], service.element);
        },
        callBackGeneric: function (response) {
            if (service.dataCallBackGeneric !== null) {
                window[service.dataCallBackGeneric](response.data[service.responseData], service.element);
            }
        },
        init: function (response) {
            if (!service.response.modalFromTo()) {
                if (service.dataReload === "page") {
                    service.response.reload.page();
                } else if (service.dataReload === "modal") {
                    service.response.reload.modal();
                } else if (service.dataReload === "parentModal") {
                    service.response.reload.parentModal();
                } else if (service.dataReload === "modalParentModal") {
                    service.response.reload.modalParentModal();
                } else if (service.dataReload === "modalParentParentModal") {
                    service.response.reload.modalParentParentModal();
                } else if (service.dataReload === "parentParentModal") {
                    service.response.reload.parentParentModal();
                } else if (service.dataReload === "parentToggle") {
                    service.response.reload.parentToggle();
                } else if (service.dataReload === "empty") {
                    service.response.reload.emptyMessage();
                } else if (service.dataRedirect !== null) {
                    service.response.redirect.page(response);
                } else if (service.dataCallBack !== null) {
                    service.response.callBack(response);
                } else {
                    service.response.reload.default(response);
                }
                service.response.callBackGeneric(response);
            }
            service.response.alertMessages(response);
        }
    };

    service.init = function () {
        service.set.variable();
        if (!service.button.isStop()) {
            if (service.confirm.check()) {
                service.button.disable();
                if (service.formControl.validation()) {
                    if (service.response.callBefore()) {
                        service.request.send();
                    }
                }
            }
        }
    };

    service.init();
};

export const getUtil = (element) => {
    let form;
    let valid = true;
    let rtn = false;
    if (_elementUtil.getType(element) === 'form') {
        form = element;
    } else {
        form = element.parents('form').first();
    }
    if (form.length === 1) {
        valid = formValidation(form);
    }
    if (valid) {
        let query = serviceQueryBuilder(element, "query");
        rtn = query.process;
        if (query.process) {
            if (typeof element.attr("data-modal-href-org") === 'undefined') {
                element.attr("data-modal-href-org", element.attr("data-modal-href"));
            }
            let newUrl = element.attr("data-modal-href-org") + "?" + query.query;
            element.attr("data-modal-href", newUrl);
        }
    }

    return rtn;
};

export default {serviceQueryBuilder, serviceUrlBuilder, serviceUtil, getUtil};
