import $ from "jquery";
import select2Util from "utilPath/select2-util";

export const removeValidation = (element) => {
    element.removeClass("error-validation");
    if (typeof element.data('bs.tooltip') !== 'undefined') {
        element.tooltip('dispose');
    }
    if (typeof element.attr('aria-describedby') !== 'undefined') {
        $("#" + element.attr('aria-describedby')).remove();
    }

    if (select2Util.select2IsExists(element)) {
        let slt2 = element.next("span");
        slt2.removeClass("error-validation");
        if (typeof slt2.data('bs.tooltip') !== 'undefined') {
            slt2.tooltip('dispose');
        }
        if (typeof slt2.attr('aria-describedby') !== 'undefined') {
            $("#" + slt2.attr('aria-describedby')).remove();
        }
    }
};

export default {
    removeValidation
}