<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "../tools/node_modules/bootstrap-vue/dist/bootstrap-vue.css";
@import "../tools/node_modules/perfect-scrollbar/css/perfect-scrollbar.css";
@import "../tools/node_modules/socicon/css/socicon.css";
@import "../tools/node_modules/animate.css";
@import "../tools/node_modules/@fortawesome/fontawesome-free/css/all.css";
@import "../tools/node_modules/line-awesome/dist/line-awesome/css/line-awesome.css";
@import "../tools/node_modules/jquery-ui/themes/base/all.css";
@import "../tools/node_modules/datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "../tools/node_modules/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
@import "../tools/node_modules/datatables.net-autofill-bs4/css/autoFill.bootstrap4.min.css";
@import "../tools/node_modules/datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css";
@import "../tools/node_modules/datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css";
@import "../tools/node_modules/datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css";
@import "../tools/node_modules/datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css";
@import "../tools/node_modules/datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
@import "../tools/node_modules/datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css";
@import "../tools/node_modules/datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.min.css";
@import "../tools/node_modules/datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css";
@import "../tools/node_modules/datatables.net-select-bs4/css/select.bootstrap4.min.css";
@import "../tools/node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
@import "../tools/node_modules/sweetalert2/dist/sweetalert2.css";
@import "../assets/plugins/flaticon/flaticon.css";
@import "../assets/plugins/flaticon2/flaticon.css";
@import "../assets/plugins/keenthemes-icons/font/ki.css";
@import "../assets/plugins/datatables.css";
@import "../assets/plugins/auto-complete.css";

// Main demo style scss
@import "../assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "../tools/src/core/services/store/config.module";

export default {
  name: "MetronicVue",
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  }
};
</script>
