// en-US
export const locale = {
    noResultsFound: 'No results found.',
    noDataAvailableInTable: 'No data available in table.',
    internalServerError: 'Internal Server Error',
    Code:'Code',
    yes: 'Yes',
    dataTable: {
        decimal: "",
        emptyTable: "No data available in table.",
        info: "Showing _START_ to _END_ of _TOTAL_ entries.",
        lengthMenu: "Show _MENU_ entries.",
        sInfo: "Showing _START_ to _END_ of _TOTAL_ entries.",
        sInfoEmpty: "Showing 0 to 0 of 0 entries.",
        loadingRecords: "Loading...",
        search: "Search:",
        zeroRecords: "No matching records found",
        aria: {
            sortAscending: ":Activate to sort column ascending",
            sortDescending: ":Activate to sort column descending"
        },
        processing: '<div class="spinner-grow " role="status">' +
            '<span class="sr-only"></span>' +
            '</div>',
        paginate: {
            first: "First",
            last: "Last",
            next: '<i class="fa fa-chevron-right" ></i>',
            previous: '<i class="fa fa-chevron-left" ></i>'
        }
    },
    numberFormatUtil: {
        validationMin: "Please enter a value greater than {min}",
        validationMax: "Please enter a value less than {max}"
    },
    dateFormat: {
        minDate: 'please enter a valid date',
        maxDate: 'please enter a valid date'
    },
};
