import $ from "jquery";
import store from "corePath/services/store";
import select2Util from "./select2-util";
import dataTable from "./data-table";
import {SET_BREADCRUMB} from "../services/store/breadcrumbs.module";
import {responsiveTable} from "./responsive-table-util";
import {getElementByAttr, setElementUtil} from "./element-util";
import {numberFormatUtil} from "./number-format-util";
import {autoCompleteUtil} from "./auto-complate-util";
import {dateUtil} from "./datepicker-util";
import {dateFormat} from "./data-type-dateformat";
import {emailUtil} from "./data-type-email";
import {serviceUtil} from "./service-util"
import {loadUtil} from "./load-util";
import {openModal} from "./modal";
import {serviceGetMessage} from "./helper";
import {printUtil} from "./printUtil";
import {removeValidation} from "./removeValidation";

export const coreJs = {
    init: (data) => {
        document.title = window.$envConfig.PROJECT + ' | ' + data.title;
        store.dispatch(SET_BREADCRUMB, [{title: data.title}]);

        //select2Util
        $("[data-select=true]").each(function () {
            select2Util.select2Create($(this));
        });

        //dataTable
        $("[data-table-type=datatable]").each(function () {
            if(typeof $(this).attr("id") !== 'undefined' && typeof window.allDatableObj["#" + $(this).attr("id")] === 'undefined') {
                window.allDatableObj["#" + $(this).attr("id")] = dataTable.loadDataTables($(this));
            }
        });

        $("[data-table-type=datatablehtml]").each(function () {
            if(typeof $(this).attr("id") !== 'undefined' && typeof  window.allDatableObj["#" + $(this).attr("id")] === 'undefined') {
                window.allDatableObj["#" + $(this).attr("id")] = dataTable.loadDataTablesHtml($(this));
            }
        });

        $("[data-search-prop-key]").each(function () {
            dataTable.changeSearchKey($(this));
        });

        //responsiveTable
        $("table.responsive-table").each(function () {
            responsiveTable($(this));
        });

        //elementUtil
        let propElements = getElementByAttr("data-prop-");
        setElementUtil(propElements);

        //numberFormatUtil
        $('[data-type=decimal],[data-type=money],[data-type=number]').each(function () {
            numberFormatUtil($(this));
        });

        //autoCompleteUtil
        $("[data-autocomplete]").each(function () {
            autoCompleteUtil($(this));
        });

        //dateUtil
        $('[data-type=date]').each(function () {
            dateUtil($(this));
        });

        //dateFormat
        $('[data-type=dateFormat]').each(function () {
            dateFormat($(this));
        });

        //emailUtil
        $('[data-type=email]').each(function () {
            emailUtil($(this), false);
        });

        //serviceUtil
        $(document).on('click', "[data-method][data-method!=inline-edit]", function (e) {
            serviceUtil($(this));
        });

        //printUtil
        $('[data-print-prop-click]').each(function () {
            if($(this).attr('data-print-param-loaded') === 'false') {
                $(this).attr('data-print-param-loaded', 'true');
                printUtil($(this));
            }
        });

        //loadUtil
        $('[data-load-url]').each(function (index) {
            loadUtil($(this), index);
        });
        $('[data-load-url]').on("click", function () {
            $(this).loadUtilLoad();
        });

        $("[data-method][data-method!=inline-edit]").each(function () {
            if (typeof $(this).attr("data-method-autorun") !== 'undefined' && $(this).attr('data-method-autorun') === 'true') {
                $(this).trigger("click");
            }
        });

        //openModal
        /***
        $(document).on('click', "[data-modal-href]", function () {
            openModal(
                $(this),
                $(this).attr("data-modal-href"),
                typeof $(this).attr('data-modal-size') !== 'undefined' ? $(this).attr('data-modal-size') : null
            );
        });

        $(document).keydown(function (event) {
            if (event.keyCode === 27) {
                let openModal = $('.modal.show');
                if (openModal.length > 0) {
                    openModal.modal('hide');
                    if (typeof openModal.attr('data-modal-parent-id') !== 'undefined' && openModal.attr('data-modal-parent-id') !== null) {
                        let parentModal = $("#" + openModal.attr('data-modal-parent-id'));
                        if (parentModal.length > 0) {
                            parentModal.modal('show');
                            parentModal.find("[data-select=true]").each(function () {
                                let elt = "#" + parentModal.attr('id') + " > #" + $(this).attr("id");
                            });
                        }
                    }
                }
            }
        });
         ***/

        //helper
        serviceGetMessage();
    },
    initStart: () => {

    },
    initContainer:(data) => {
        document.title = window.$envConfig.PROJECT + ' | ' + data.title;
        store.dispatch(SET_BREADCRUMB, [{title: data.title}]);

        let container = $(data.container);

        //select2Util
        container.find("[data-select=true]").each(function () {
            select2Util.select2Create($(this));
        });

        //dataTable
        container.find("[data-table-type=datatable]").each(function () {
            if(typeof $(this).attr("id") !== 'undefined' ) { //&& typeof window.allDatableObj["#" + $(this).attr("id")] === 'undefined'
                window.allDatableObj["#" + $(this).attr("id")] = dataTable.loadDataTables($(this));
            }
        });

        container.find("[data-table-type=datatablehtml]").each(function () {
            if(typeof $(this).attr("id") !== 'undefined' ) { //&& typeof  window.allDatableObj["#" + $(this).attr("id")] === 'undefined'
                window.allDatableObj["#" + $(this).attr("id")] = dataTable.loadDataTablesHtml($(this));
            }
        });

        container.find("[data-search-prop-key]").each(function () {
            dataTable.changeSearchKey($(this));
        });

        //responsiveTable
        container.find("table.responsive-table").each(function () {
            responsiveTable($(this));
        });

        //elementUtil
        let propElements = getElementByAttr("data-prop-",data.container);
        setElementUtil(propElements);

        //numberFormatUtil
        container.find('[data-type=decimal],[data-type=money],[data-type=number]').each(function () {
            numberFormatUtil($(this));
        });

        //autoCompleteUtil
        container.find("[data-autocomplete]").each(function () {
            autoCompleteUtil($(this));
        });

        //dateUtil
        container.find('[data-type=date]').each(function () {
            dateUtil($(this));
        });

        //dateFormat
        container.find('[data-type=dateFormat]').each(function () {
            dateFormat($(this));
        });

        //emailUtil
        container.find('[data-type=email]').each(function () {
            emailUtil($(this), false);
        });

        //printUtil
        container.find('[data-print-prop-click]').each(function () {
            if($(this).attr('data-print-param-loaded') === 'false') {
                $(this).attr('data-print-param-loaded', 'true');
                printUtil($(this));
            }
        });

        //loadUtil
        container.find('[data-load-url]').each(function (index) {
            loadUtil($(this), index);
        });
        container.find('[data-load-url]').on("click", function () {
            $(this).loadUtilLoad();
        });

        container.find("[data-method][data-method!=inline-edit]").each(function () {
            if (typeof $(this).attr("data-method-autorun") !== 'undefined' && $(this).attr('data-method-autorun') === 'true') {
                $(this).trigger("click");
            }
        });

    },
    close: (data) => {
        let container = $(data.container);
        container.find('[aria-describedby]').each(function () {
            removeValidation($(this));
        });
    }
};

export default {coreJs};