import ApiService from "corePath/services/api.service";
import JwtService from "corePath/services/jwt.service";
import helper from "utilPath/helper";
import {initError} from "utilPath/handleError";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGOUT = "logout";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
    errors: null,
    user: {},
    isAuthenticated: !!JwtService.getToken("state"),
    accessToken: null,
    indicator: null,
    company: null
};

const getters = {
    currentUser(state) {
        return state.user;
    },
    currentCompany(state) {
        return state.company;
    },
    accessToken(state) {
        return state.accessToken;
    },
    indicator(state) {
        return state.indicator;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    }
};

const actions = {
    [LOGOUT](context) {
        context.commit(PURGE_AUTH);
    },
    [VERIFY_AUTH](context) {
        if (JwtService.getToken("VERIFY_AUTH")) {
            return new Promise(resolve => {
                ApiService.setHeader();
                ApiService.get("auth/verify")
                    .then(({data}) => {
                        context.commit(SET_AUTH, data);
                        resolve(data);
                    })
                    .catch((error) => {
                        initError(error);
                    });
            });
        } else if (JwtService.checkIndicator()) {
            return new Promise(resolve => {
                let credentials = {
                    indicator: JwtService.getIndicator(),
                    userId: JwtService.getUserId()
                };

                ApiService.post("auth/check-indicator", credentials)
                    .then(({data}) => {
                        context.commit(SET_AUTH, data);
                        resolve(data);
                    })
                    .catch((error) => {
                        initError(error);
                    });
            });
        } else {

            context.commit(PURGE_AUTH);
            helper.loginRedirect();
        }
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_AUTH](state, data) {
        state.isAuthenticated = true;
        state.accessToken = data.accessToken;
        state.indicator = data.indicator;
        state.user = data.user;
        state.company = data.company;
        state.errors = {};
        JwtService.saveToken(data.accessToken);
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.user = {};
        state.errors = {};
        state.company = {};
        state.indicator = null;
        state.accessToken = null;
        JwtService.destroyToken();
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
