import $ from "jquery";
import screen from "./screen";


export const responsiveTable = (mainTable) => {
    let table = {
        main: mainTable,
        cssSize: null,
        titles: [],
    };

    table.render = {
        getTitles: function () {
            table.main.find("thead > tr > th").each(function () {
                table.titles.push([
                    $(this).text() + ($(this).text() !== "" ? " :" : ""),
                    !!$(this).hasClass("m-hide"),
                    !!$(this).hasClass("m-open"),
                ]);
            });
        },
        getTitle: function (index) {
            return table.titles[index][0];
        },
        getTitleContent: function (index) {
            return table.render.getTitle(index) !== '' ? '<span>' + table.render.getTitle(index) + '</span>' : '';
        },
        setTitle: function (elt) {
            let indexCell = elt.index();
            elt.attr("data-rt-label", table.render.getTitle(indexCell));
        },
        titleIsHide: function (elt) {
            let indexCell = elt.index();
            if (table.titles[indexCell][1] === true) {
                elt.addClass("m-hide");
            }
            return table.titles[indexCell][1];
        },
        setHideContent: function (elt) {
            let indexCell = elt.index();
            return '<div class="m-div">' + table.render.getTitleContent(indexCell) + elt.html() + '</div>';
        },
        isOpenIcon: function (elt) {
            let indexCell = elt.index();
            if (table.titles[indexCell][2] === true) {
                elt.html('<i class="m-opened"></i>' + elt.html());
                elt.find(".m-opened").first().on("click", function () {
                    let _e = elt.parents("tr").find(".m-td-group").first();
                    if (_e.hasClass("d-none")) {
                        _e.removeClass("d-none");
                    } else {
                        _e.addClass("d-none");
                    }
                });
            }

        },
        setHiddenContent: function (elt, content) {
            elt.append('<td class="m-td-group d-none">' + content + '</td>');
        },
        renderBody: function () {
            table.main.find("tbody > tr").each(function () {
                let hiddenContent = "";
                $(this).find("td").each(function () {
                    table.render.setTitle($(this));
                    if (table.render.titleIsHide($(this))) {
                        hiddenContent += table.render.setHideContent($(this));
                    }
                    table.render.isOpenIcon($(this));
                });
                table.render.setHiddenContent($(this), hiddenContent);
            });
        },
        init: function () {
            table.render.getTitles();
            table.render.renderBody();
        }
    };

    table.cssSize = screen.getCssSize();
    if (table.cssSize === 'xs') {
        table.render.init();
    }
};

export default {responsiveTable}
