import $ from "jquery";

export const printUtil = (button) => {
    let print = {
        button: button,
        container: null,
        prefix: 'data-print',
        printSection: null,
    };

    print.set = {
        container: function () {
            print.container = $(print.prop.getParam(print.button, 'container'));
            print.container.attr('data-print-container', 'true');
        },
        init: function () {
            print.set.container();
        }
    };

    print.prop = {
        createPrintSection: function () {
            let id = 'printSection';
            if ($('#' + id).length === 0) {
                $('body').append($('<div />', {
                    'id': id,
                    'class': 'printSection'
                }));
            }
            print.printSection = $('#' + id);
        },
        print: function () {
            print.set.container();
            $('body').addClass('nonPrintSection');
            let domClone = print.container.clone(true);
            print.printSection.html(domClone);
            window.print();
        },
        getAttr: function (elt, attr) {
            if (typeof elt.attr(print.prefix + '-' + attr) !== 'undefined') {
                return elt.attr(print.prefix + '-' + attr);
            } else {
                return null;
            }
        },
        getParam: function (elt, param) {
            if (typeof elt.attr(print.prefix + '-param-' + param) !== 'undefined') {
                return elt.attr(print.prefix + '-param-' + param);
            } else {
                return null;
            }
        },
        setParam: function (elt, param, value) {
            if (typeof elt.attr(print.prefix + '-param-' + param) !== 'undefined') {
                elt.attr(print.prefix + '-param-' + param, value);
            }
        },
        setClickButton: function (elt, source) {
            let spl = elt.attr(print.prefix + '-prop-click').split('.');
            elt.on('click', function () {
                if (spl.length === 1) {
                    print.prop[spl[0]]($(this), source);
                } else if (spl.length === 2) {
                    print.prop[spl[0]][spl[1]]($(this), source);
                } else if (spl.length === 3) {
                    print.prop[spl[0]][spl[1]][spl[2]]($(this), source);
                }
            });
        },
        init: function () {
            print.prop.createPrintSection();
            print.prop.setClickButton(print.button);
        }
    }
    print.set.init();
    print.prop.init();
};



