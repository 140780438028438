import i18n from "../plugins/vue-i18n";
import {alertMessage} from "./helper";

export const dateFormat = (element) => {
    let date = {
        element: element,
        maxDate: null,
        minDate: null,
        language: i18n.messages.dateFormat
    };

    date.set = {
        maxDate: function () {
            date.maxDate = typeof date.element.attr('data-date-max') !== 'undefined' ? date.element.attr('data-date-max') : null;
        },
        minDate: function () {
            date.minDate = typeof date.element.attr('data-date-min') !== 'undefined' ? date.element.attr('data-date-min') : null;
        },
        init: function () {
            date.set.maxDate();
            date.set.minDate();
        }
    };

    date.prop = {
        format: function (date) {
            let spl = date.split("-");
            return spl[2] + "/" + spl[1] + "/" + spl[0];
        },
        calculateDates: function (start, end) {
            let startDate = new Date(start);
            let endDate = new Date(end);
            return Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
        },
        validateDate: function (inputDate) {
            let value = inputDate;
            if (value.length === 10) {
                let spl = value.split("/");
                let day = spl[0];
                let month = spl[1];
                let year = spl[2];
                let today = year + "-" + month + "-" + day;
                if (date.minDate !== null) {
                    let diff = date.prop.calculateDates(date.minDate, today);
                    if (diff < 0) {
                        alertMessage(date.language.minDate + ':' + date.prop.format(date.minDate));
                        return false;
                    }
                }
                if (date.maxDate !== null) {
                    let diff = date.prop.calculateDates(date.maxDate, today);
                    if (diff > 0) {
                        alertMessage(date.language.maxDate + ':' + date.prop.format(date.maxDate));
                        return false;
                    }
                }
            }

            return true;
        },
        checkValue: function (str, max) {
            if (str.charAt(0) !== '0' || str === '00') {
                let num = parseInt(str);
                if (isNaN(num) || num <= 0 || num > max) num = 1;
                str = num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? '0' + num : num.toString();
            }
            return str;
        },
        keyUp: function () {
            let value = date.element.val();
            if (/\D\/$/.test(value)) value = value.substr(0, value.length - 3);
            let values = value.split('/').map(function (v) {
                return v.replace(/\D/g, '');
            });
            if (values[0]) values[0] = date.prop.checkValue(values[0], 31);
            if (values[1]) values[1] = date.prop.checkValue(values[1], 12);
            let output = values.map(function (v, i) {
                return v.length === 2 && i < 2 ? v + '/' : v;
            });
            date.element.val(output.join('').substr(0, 14));
        },
        blur: function () {
            let output = '';
            let value = date.element.val();
            let values = value.split('/').map(function (v, i) {
                return v.replace(/\D/g, '')
            });
            if (values.length === 3) {
                let year = values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
                let month = parseInt(values[1]) - 1;
                let day = parseInt(values[0]);
                let d = new Date(year, month, day);
                if (!isNaN(d)) {
                    let dates = [d.getDate(), d.getMonth() + 1, d.getFullYear()];
                    output = dates.map(function (v) {
                        v = v.toString();
                        return v.length === 1 ? '0' + v : v;
                    }).join('/');
                }
            }
            if (date.prop.validateDate(output)) {
                date.element.val(output);
            } else {
                date.element.val("");
            }
        },
        init: function () {
            element.on('blur', function () {
                date.prop.blur();
            });
            element.on('keyup', function () {
                date.prop.keyUp();
            });
        }
    };

    date.set.init();
    date.prop.init();
};

export default {dateFormat}
