import $ from 'jquery';
import i18n from "../plugins/vue-i18n";
import cookie, {setCookie} from "./cookie";
import Swal from 'sweetalert2/dist/sweetalert2.all';
import toastr from 'toastr';
import authModule from '../services/store/auth.module';

export const loginRedirect = () => {
    let url = removeUrlParameter(window.location.href, 'indicator');
    url = removeUrlParameter(url, 'userId');
    authModule.mutations.logOut(authModule.state);
    setCookie("lastUrl",url);
    window.location = window.$envConfig.ACCOUNTS_URL + "/login?system=rep&continue=" + encodeURIComponent(window.$envConfig.BASE_URL+"/api/login-check");
}

export const getQueryParameterByName = (name) => {
    name = name.replace(/[\\[\\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const getLocale = () => {
    return i18n.locale;
};

export const alertMessage = (message) => {
    toastr.options = {
        closeButton: true,
        timeOut: 3000,
        positionClass: "toast-bottom-right",
    };
    toastr.success(message);
};

export const warningMessage = (message) => {
    toastr.options = {
        closeButton: true,
        timeOut: 3000,
        positionClass: "toast-bottom-right",
    };
    toastr.warning(message);
};

export const errorToastrMessage = (message) => {
    toastr.options = {
        closeButton: true,
        timeOut: 3000,
        positionClass: "toast-bottom-right",
    };
    toastr.error(message);
};

export const errorMessage = (message) => {
    Swal.fire({title:"Error!",html: message, icon:"error"});
};

export const serviceGetMessage = () => {
    let message = cookie.getCookie("service-message");
    if (message != null) {
        setTimeout(function () {
            alertMessage(message);
        }, 300);
        cookie.deleteCookie("service-message");
    }
};

export const tooltipMessage = (element, message, zIndex, type) => {
    if (typeof element.data('bs.tooltip') === 'undefined') {
        let container = element.data('msg-container') || false;
        let selector = element.data('msg-selector') || false;
        let options = {
            animation: element.data('msg-animation') || true,
            html: element.data('msg-html') || false,
            placement: function (context, element) {
                let position = $(element).position();
                if (position.top - $(window).scrollTop() < 115) {
                    return "top";
                }
                return "top";
            },
            //placement: $(element).data('msg-placement') || 'top',
            selector: selector,
            title: element.attr('msg-title') || message,
            trigger: $.trim('manual ' + (element.data('trigger') || '')),
            delay: element.data('msg-delay') || 0,
            container: container,
            boundary: 'window',
            offset: 0,
            template: '<div class="tooltip ' + (typeof element.data('msg-type') !== 'undefined' ? 'tooltip-' + element.data('msg-type') : '') + (typeof type !== 'undefined' ? 'tooltip-' + type : '') + '" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
        };

        element.tooltip(options);
    }
    element.addClass("error-validation");
    element.tooltip('show');

    let tp = $("#" + element.attr("aria-describedby"));
    tp.css({
        "z-index": typeof zIndex !== 'undefined' && zIndex !== null ? zIndex : getzIndex(element)
    });

};

export const pageReload = (message) => {
    if (typeof message !== 'undefined' && message != null && message !== "") {
        cookie.setCookie("service-message", message, 1);
    }

    let locationUrl = location.href;
    if (location.href.indexOf("#") > -1) {
        locationUrl = locationUrl.substring(0, location.href.indexOf("#"));
    }
    window.location = locationUrl;
};

export const pageRedirect = (url, message) => {
    if (typeof message !== 'undefined' && message != null && message !== "") {
        cookie.setCookie("service-message", message, 1);
    }
    window.location = url;
};

export const pageNewWindow = (url, message) => {
    if (typeof message !== 'undefined' && message != null && message !== "") {
        cookie.setCookie("service-message", message, 1);
    }
    window.open(url);
};

export const getzIndex = () => {
    let maxZ = 0;
    if (typeof $('body').attr('data-z-index') !== 'undefined') {
        maxZ = parseInt($('body').attr('data-z-index')) + 1;
    } else {
        maxZ = Math.max.apply(null,
            $.map($('body *'), function (e) {
                if ($(e).css('position') != 'static')
                    return parseInt($(e).css('z-index')) || 1;
            }));
    }
    $('body').attr('data-z-index', maxZ);
    return parseInt(maxZ);
};

export const removeUrlParameter = (url, parameter) => {
    let urlParts = url.split('?');
    if (urlParts.length >= 2) {
        let urlBase = urlParts.shift();
        let queryString = urlParts.join('?');
        let prefix = encodeURIComponent(parameter) + '=';
        let parts = queryString.split(/[&;]/g);

        for (let i = parts.length; i-- > 0;) {
            if (parts[i].lastIndexOf(prefix, 0) !== -1) {
                parts.splice(i, 1);
            }
        }

        let query = parts.join('&');
        url = urlBase + (query !== "" ? '?' + parts.join('&') : "");
    }

    return url;
}

export const objectLength = (object) => {
    return Object.keys(object).length;
};

export const isMobile = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}

export const isRTL = () => {
    let rtl = ['ar', 'he', 'ur'];
    let locale = i18n.locale.substr(0, 2);
    return rtl.includes(locale);
}

export const language = () => {
    return i18n.locale.substr(0, 2);
}

export const serviceHeaderBuilder = (r) => {
    r.setRequestHeader("Accept-Language", getLocale().replace('_','-'));
    r.setRequestHeader("Authorization", "Bearer " + authModule.state.accessToken);
};

export const addLoader = (element) =>{
    element.setAttribute('disabled','true');
    element.innerHTML += '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
};
export const removeLoader = (element) =>{
    element.removeAttribute('disabled');
    if(element.querySelector("span") !== null) {
        element.querySelector("span").remove();
    }
};

export default {
    loginRedirect,
    getQueryParameterByName,
    getLocale,
    alertMessage,
    warningMessage,
    errorToastrMessage,
    errorMessage,
    serviceGetMessage,
    tooltipMessage,
    pageReload,
    pageRedirect,
    pageNewWindow,
    getzIndex,
    removeUrlParameter,
    objectLength,
    isMobile,
    isRTL,
    language,
    serviceHeaderBuilder
};