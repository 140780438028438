import $ from "jquery";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker";
import helper from "./helper";

export const dateUtil = (element) => {
    let date = {
        element: element,
        arrows: {},
        params: {},
        fromElement: null,
        toElement: null,
        parent: "",
    };

    date.init = function () {
        date.getParams.isModal();
        date.getParams.fromElement();
        date.getParams.toElement();
        date.setParams();
        date.createDate();
    };

    date.setParams = function () {
        date.params = {
            rtl: date.getParams.rtl(),
            language: date.getParams.language(),
            weekStart: date.getParams.weekStart(),
            todayHighlight: date.getParams.todayHighlight(),
            orientation: date.getParams.orientation(),
            //templates: date.getParams.arrows(),
            format: date.getParams.format(),
            startDate: date.getParams.startDate(),
            endDate: date.getParams.endDate(),
            calendarWeeks: date.getParams.calendarWeeks(),
            autoclose: date.getParams.autoClose(),
            changeMonth: true,
        };
    };

    date.createDate = function () {
        let dp = date.element.datepicker(date.params);
        if (date.toElement !== null) {
            dp.on('hide', function (selected) {
                if (date.toElement.val() === "") {
                    if ($(this).val() !== "") {
                        let spl = $(this).val().split("/");
                        let day = spl[0];
                        let month = spl[1];
                        let year = spl[2];
                        let fromDate = new Date(year + "-" + month + "-" + day);
                        let toDate = new Date(year + "-" + month + "-" + day);

                        toDate.setDate(fromDate.getDate() + 1);
                        date.toElement.val(date.getParams.dateFormat(toDate));

                        let minDate = new Date(year + "-" + month + "-" + day);
                        date.toElement.datepicker('setStartDate', minDate);
                    }
                } else {
                    if ($(this).val() !== "") {
                        let spl = $(this).val().split("/");
                        let day = spl[0];
                        let month = spl[1];
                        let year = spl[2];

                        let fromDate = new Date(year + "-" + month + "-" + day);
                        spl = date.toElement.val().split("/");
                        day = spl[0];
                        month = spl[1];
                        year = spl[2];
                        let toDate = new Date(year + "-" + month + "-" + day);

                        let nextDate = date.getParams.dateFormat(fromDate);
                        spl = nextDate.split("/");
                        day = spl[0];
                        month = spl[1];
                        year = spl[2];
                        let minDate = new Date(year + "-" + month + "-" + day);
                        date.toElement.datepicker('setStartDate', minDate);
                        if (toDate < fromDate) {
                            toDate = fromDate;
                            toDate.setDate(fromDate.getDate() + 1);
                            nextDate = date.getParams.dateFormat(toDate);
                            date.toElement.val(nextDate);

                            spl = nextDate.split("/");
                            day = spl[0];
                            month = spl[1];
                            year = spl[2];
                            minDate = new Date(year + "-" + month + "-" + day);
                            date.toElement.datepicker('setDate', minDate);
                        }
                    }
                }
            });
        }
    };

    date.getParams = {
        isModal: function () {
            if (date.element.parents(".modal").length > 0) {
                date.parent = "#" + date.element.parents(".modal").attr("id") + " ";
            }
        },
        rtl: function () {
            return helper.isRTL();
        },
        language: function () {
            return helper.language();
        },
        weekStart: function () {
            return typeof date.element.attr("data-date-week-start") !== 'undefined' ? parseInt(date.element.attr("data-date-week-start")) : 1;
        },
        format: function () {
            return typeof date.element.attr("data-date-format") !== 'undefined' ? date.element.attr("data-date-format") : "dd/mm/yyyy";
        },
        todayHighlight: function () {
            return !(typeof date.element.attr("data-date-today-highlight") !== 'undefined' && date.element.attr("data-date-today-highlight") === 'false');
        },
        orientation: function () {
            return typeof date.element.attr("data-date-position") !== 'undefined' ? date.element.attr("data-date-position") : "auto";
        },
        arrows: function () {
            if (helper.isRTL()) {
                date.arrows = {
                    leftArrow: '<i class="la la-angle-right"></i>',
                    rightArrow: '<i class="la la-angle-left"></i>'
                };
            } else {
                date.arrows = {
                    leftArrow: '<i class="la la-angle-left"></i>',
                    rightArrow: '<i class="la la-angle-right"></i>'
                };
            }
            return date.arrows;
        },
        startDate: function () {
            return typeof date.element.attr("data-date-start-date") !== 'undefined' ? date.element.attr("data-date-start-date") : null;
        },
        endDate: function () {
            return typeof date.element.attr("data-date-end-date") !== 'undefined' ? date.element.attr("data-date-end-date") : null;
        },
        calendarWeeks: function () {
            return (typeof date.element.attr("data-date-calendar-weeks") !== 'undefined' && date.element.attr("data-date-calendar-weeks") === 'true');
        },
        autoClose: function () {
            return !(typeof date.element.attr("data-date-auto-close") !== 'undefined' && date.element.attr("data-date-auto-close") === 'false');
        },
        fromElement: function () {
            if (typeof date.element.attr("data-date-from-date") !== 'undefined') {
                date.fromElement = $(date.parent + date.element.attr("data-date-from-date"));
                return true;
            }
            return false;
        },
        toElement: function () {
            if (typeof date.element.attr("data-date-to-date") !== 'undefined') {
                date.toElement = $(date.parent + date.element.attr("data-date-to-date"));
                return true;
            }
            return false;
        },
        dateFormat: function (date) {
            return (date.getDate() < 10 ? '0' : '') + date.getDate() + "/" + (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1) + "/" + date.getFullYear();
        }

    };

    date.init();
};

export default {dateUtil};




