/**
 * data-type=email
 */
import {removeValidation} from "./removeValidation";
import {tooltipMessage} from "./helper";

export const emailUtil = (element, process) => {
    let email = {
        element: element,
        process: process,
        message: null,
        newValue: null,
        event: null,
        isRequired: false,
    };

    email.set = {
        message: function () {
            if (email.process) {
                email.message = typeof email.element.attr('data-msg-required') !== 'undefined' ? email.element.attr('data-msg-required') : email.element.attr('data-msg-email');
            } else {
                email.message = email.element.attr('data-msg-email');
            }

        },
        isRequired: function () {
            email.isRequired = typeof email.element.attr('required') !== 'undefined' && (email.element.attr('required') === "true" || email.element.attr('required') === "required");
        },
        init: function () {
            email.set.message();
            email.set.isRequired();
        }
    };

    email.prop = {
        charControl: function () {
            let charCode = (email.event.which) ? email.event.which : email.element.keyCode;
            let charStr = String.fromCharCode(charCode);
            if (charStr === " ") {
                return false;
            }
            return !/[!#$%^&*(),?":{}|<>\[\]\/\\]/g.test(charStr);

        },
        lowerCase: function () {
            email.newValue = email.element.val().replace(/Ü/g, 'ü').replace(/I/g, 'ı').replace(/Ğ/g, 'ğ').replace(/Ş/g, 'ş').replace(/İ/g, 'i').replace(/Ö/g, 'ö').replace(/Ç/g, 'ç').toLowerCase();
            email.element.val(email.newValue);
        },
        render: function () {
            let value = email.element.val();
            if (value === "" && !email.isRequired) {
                return true;
            }

            let at = value.indexOf("@");
            let dot = -1;
            if (at > -1) {
                dot = value.substr(at, value.length).indexOf(".");
                if (dot > 1) {
                    if (dot < value.length - 1) {
                        return true;
                    }
                }
            }
            return false;
        },
        focus: function () {
            removeValidation(email.element);
        },
        blur: function () {
            email.prop.lowerCase();
            if (email.prop.render() === false) {
                tooltipMessage(email.element, email.message);
            }
        }

    };
    email.set.init();

    if (process) {
        removeValidation(email.element);
        email.prop.lowerCase();
        if (email.prop.render() === false) {
            tooltipMessage(email.element, email.message);
            return false;
        }
        return true;
    } else {
        email.element.on("focus", function () {
            email.prop.focus();
        });

        email.element.on("blur", function () {
            email.prop.blur();
        });

        email.element.on("keypress", function () {
            email.event = event;
            return email.prop.charControl(event);
        });
    }
};

export default {emailUtil}


