import $ from 'jquery';
import 'select2/dist/js/select2.full';
import i18n from "../plugins/vue-i18n";
//import removeValidation from "utilPath/removeValidation";

export const select2OptionFormat = (option) => {
    let originalOption = option.element;
    if ($(originalOption).attr('data-html')) {
        return $(originalOption).attr('data-html');
    }

    return option.text;
};

export const select2IsExists = (element) => {
    return typeof element.attr("data-select-add") !== 'undefined' && element.attr("data-select-add") === "true" ? true : false;
};

export const select2Create = (element) => {
    if (typeof element.attr('data-select-add') === 'undefined') {
        let jsonData = typeof element.attr("data-select-params") !== 'undefined' ? JSON.parse(element.attr("data-select-params")) : {};
        if (element.attr("data-select-render") === 'true') {
            jsonData['templateResult'] = select2OptionFormat;
            jsonData['templateSelection'] = select2OptionFormat;
            jsonData['escapeMarkup'] = function (m) {
                return m;
            };
        }
        jsonData['language'] = {
            "noResults": function () {
                return i18n.messages.noResultsFound;
            }
        };
        element.select2(jsonData);

        if (element.hasClass("price-blank")) {
            element.next().find(".select2-selection").addClass('price-blank');
        }
        if (element.hasClass("price-not-blank")) {
            element.next().find(".select2-selection").addClass('price-not-blank');
        }
        if (element.hasClass("readonly")) {
            element.next().find(".select2-selection").addClass('readonly');
        }
        element.attr('data-select-add', 'true');

        element.on("select2:select", function () {
         //   removeValidation($(this));
        });
    }

    return true;
};

export const select2Remover = (element) => {
    if (typeof element.attr('data-select-add') !== 'undefined') {
        if (element.hasClass("select2-hidden-accessible")) {
            element.select2('destroy');
            element.off('select2:select');
            element.removeAttr('data-select-add');
            element.removeAttr('data-select2');
            element.removeAttr('aria-hidden');
            element.removeAttr('data-select2-id');

            element.find("option").each(function () {
                $(this).removeAttr("data-select2-id");
            });
        }
    } else {
        alert("select2Remover YOK");
    }
    return true;
};

export default {
    select2OptionFormat,
    select2IsExists,
    select2Create,
    select2Remover
}

