import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list
import { locale as en_US } from "corePath/translate/en_US";
import { locale as tr_TR } from "corePath/translate/tr_TR";

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, en_US, tr_TR };

// get current selected language
const lang = localStorage.getItem("language") || "en_US";


// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages // set locale messages
});

export default i18n;
