import $ from "jquery";
import i18n from "../plugins/vue-i18n";
import {errorMessage} from "./helper";

/**
 * data-type=number|decimal|money
 * data-number-currency=EUR
 * data-number-digit=2
 */
export const numberFormatUtil = (element) => {
    let numberFormat = {
        element: element,
        event: null,
        type: element.attr("data-type"),
        digit: null,
        currency: null,
        isNegative: false,
        memory: "",
        language: i18n.getLocaleMessage(i18n.locale).numberFormatUtil,
    };

    let set = {
        decimal: function () {
            numberFormat.digit = typeof numberFormat.element.attr('data-number-digit') !== 'undefined' ? numberFormat.element.attr('data-number-digit') : 2;
        },
        type: function () {
            numberFormat.type = numberFormat.element.attr('data-type');
        },
        currency: function () {
            numberFormat.currency = typeof numberFormat.element.attr('data-number-currency') !== 'undefined' ? numberFormat.element.attr('data-number-currency') : '';

            if (numberFormat.type === "money" && numberFormat.currency !== "") {
                numberFormat.element.after($("<i/>", {
                    class: "fa fa-" + numberFormat.currency.toLowerCase() + " number-format-currency",
                }));
            }
        },
        locale: function () {
            numberFormat.locale = typeof numberFormat.element.attr('data-number-locale') !== 'undefined' ? numberFormat.element.attr('data-number-locale') : '';
        },
        defaultValue: function () {
            if (numberFormat.element.val() === "") {
                if (numberFormat.type === "number") {
                    numberFormat.element.val("0");
                } else {
                    numberFormat.element.val(format.valueFormat(0, numberFormat.digit, ".", ""));
                }
            }
        },
        isNegative: function () {
            numberFormat.isNegative = typeof numberFormat.element.attr('data-number-is-negative') !== 'undefined' ? true : false;

        },
        init: function () {
            set.type();
            set.decimal();
            set.currency();
            set.locale();
            set.isNegative();
            //set.defaultValue();
            if (typeof numberFormat.element.attr('data-text-align') !== 'undefined' && numberFormat.element.attr('data-text-align') === 'left') {
                numberFormat.element.addClass("number-format-left");
            } else {
                numberFormat.element.addClass("number-format");
            }
            if (numberFormat.type !== "number") {
                numberFormat.element.addClass("number-format-decimal");
            }
        }
    };

    let format = {
        locale: function (number) {
            number = parseFloat(number);
            return number.toLocaleString(numberFormat.locale, {
                minimumFractionDigits: numberFormat.digit,
                maximumFractionDigits: numberFormat.digit,
                style: 'currency',
                currency: 'EUR'
            });
        },
        inputFilter: function () {
            let charCode = (numberFormat.event.which) ? numberFormat.event.which : numberFormat.element.keyCode;
            if (charCode < 48 || charCode > 57) {
                if (numberFormat.isNegative === true && charCode === 45) {
                    return true;
                }
                return false;
            }
            return true;
        },
        inputFilterDecimal: function () {
            let charCode = (numberFormat.event.which) ? numberFormat.event.which : numberFormat.element.keyCode;
            if ((charCode < 48 || charCode > 57) && charCode !== 46) {// charCode !== 44 &&
                if (numberFormat.isNegative === true && charCode === 45) {
                    return true;
                }
                return false;
            }

            return true;
        },
        valueFormat: function (number, decimals, dec_point, thousands_sep) {
            return numberValueFormat(number, decimals, dec_point, thousands_sep);
        },
        checkValueMin: function () {
            if (typeof numberFormat.element.attr("data-type-min") !== 'undefined' && Number(numberFormat.element.attr("data-type-min")) > Number(numberFormat.element.val())) {
                errorMessage(numberFormat.language.validationMin.replace("{min}", Number(numberFormat.element.attr("data-type-min"))));
                numberFormat.element.val("");
                return false;
            }

            return true;
        },
        checkValueMax: function () {
            if (typeof numberFormat.element.attr("data-type-max") !== 'undefined' && Number(numberFormat.element.attr("data-type-max")) < Number(numberFormat.element.val())) {
                errorMessage(numberFormat.language.validationMax.replace("{max}", Number(numberFormat.element.attr("data-type-max"))));
                numberFormat.element.val("");
                return false;
            }

            return true;
        },
        number: function () {
            if (format.checkValueMin()) {
                if (format.checkValueMax()) {
                    return true;
                }
            }
            return false;
        },
        decimal: function () {
            if (format.checkValueMin()) {
                if (format.checkValueMax()) {
                    let newValue = format.valueFormat(numberFormat.element.val(), numberFormat.digit, ".", "");
                    numberFormat.element.val(newValue);
                }
            }
        },
        money: function () {
            if (format.checkValueMin()) {
                if (format.checkValueMax()) {
                    let newValue = format.valueFormat(numberFormat.element.val(), numberFormat.digit, ".", ",");
                    //numberFormat.element.val(format.locale(numberFormat.element.val()));
                    numberFormat.element.val(newValue);
                }
            }
        },
        focus: function () {
            numberFormat.memory = numberFormat.element.val();
            numberFormat.element.val("");
        },
        blur: function () {
            if (numberFormat.element.val() === "") {
                numberFormat.element.val(numberFormat.memory);
            }
        },
        init: function () {
            //format.blur();
            if (numberFormat.element.val() === "") {
                return true;
            }
            switch (numberFormat.type) {
                case 'number':
                    return format.number();
                case 'decimal':
                    return format.decimal();
                case 'money':
                    return format.money();
                default:
                    return null;
            }
        }
    };

    set.init();
    numberFormat.element.on("keypress", function () {
        numberFormat.event = event;
        if (numberFormat.type === "number") {
            return format.inputFilter();
        } else {
            return format.inputFilterDecimal();
        }
    });
    /*numberFormat.element.on("focus", function () {
        format.focus();
    });*/
    numberFormat.element.on("blur", function () {
        format.init();
    });
    numberFormat.element.trigger("blur");
};

export const numberValueFormat = (number, decimals, dec_point, thousands_sep) => {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            let k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
};

export const numberFormatRemoveUtil = (element) => {
    element.off('blur');
    element.off('keypress');
};


