import $ from "jquery";
import {serviceHeaderBuilder} from "./helper";
import {loadModalFunctionsCall} from "./modal";
import {initError} from "./handleError";
import {getCookie} from "./cookie";

export const loadUtil = (element, index) => {
    let loader = {
        element: element,
        index: index,
        url: element.attr("data-load-url"),
        target: $("#" + element.attr("data-load-target")),
    };

    loader.element.attr("data-load-index", loader.index);
    if (typeof loader.target.attr("data-target-collapse") === 'undefined') {
        loader.target.collapse({
            toggle: false
        });
        loader.target.attr("data-target-collapse", "true");

        loader.target.on('hide.bs.collapse', function () {
        });

        loader.target.on('hidden.bs.collapse', function () {
            if (loader.target.attr("data-target-parent-now") !== loader.target.attr("data-target-parent-old")) {
                loader.target.collapse('show');
            }
        });

        loader.target.on('show.bs.collapse', function () {
            loader.target.callAjax();
        });

        loader.target.on('shown.bs.collapse', function () {
        });

        loader.target.callAjax = function () {
            if (!loader.target.hasClass("show") &&
                loader.target.attr("data-target-parent-now") !== loader.target.attr("data-target-parent-old")) {
                loader.target.append('<div class="loading-div"></div>');
                $("[data-toggle-parent-id=" + loader.target.attr("id") + "]").remove();

                $.ajax({
                    type: 'GET',
                    dataType: 'HTML',
                    url: loader.target.attr("data-collapse-ajax-url"),
                    beforeSend: serviceHeaderBuilder,
                    success: function (response) {
                        loader.target.html(response);
                        loadModalFunctionsCall("#" + loader.element.attr("data-load-target"));
                    },
                    error: function (xhr) {
                        initError(xhr);
                    }
                });
            }
        };


    }
};

export const loadToggleUtil = (element) => {
    if ($(element).length > 0) {
        $.ajax({
            type: 'GET',
            dataType: 'HTML',
            url: $(element).attr("data-collapse-ajax-url"),
            beforeSend: serviceHeaderBuilder,
            success: function (response) {
                $(element).html(response);
                loadModalFunctionsCall(element);
            },
            error: function (xhr) {
                initError(xhr);
            }
        });
    }
};

$.fn.loadUtilLoad = function () {
    let loaderUrl = $(this).attr("data-load-url");
    if (typeof $(this).attr("data-load-url-cookie") !== 'undefined') {
        let cookie = getCookie($(this).attr("data-load-url-cookie"));
        if (cookie !== null && cookie !== "") {
            loaderUrl = cookie;
        }
    }

    let loader = {
        element: $(this),
        url: loaderUrl,
        target: $("#" + $(this).attr("data-load-target")),
    };

    if (typeof loader.target.attr("data-target-collapse") === 'undefined') {
        loader.target.collapse({
            toggle: true
        });
        loader.target.attr("data-target-collapse", "true");
    }

    loader.target.attr("data-collapse-ajax-url", loader.url);
    loader.target.attr("data-target-parent-old", loader.target.attr("data-target-parent-now"));
    loader.target.attr("data-target-parent-now", loader.element.attr("data-load-index"));

    loader.target.collapse('toggle');
};

export default {loadUtil,loadToggleUtil}


