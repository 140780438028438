export const setCookie = (name, value, expireDays) => {
    let expires = "";
    if (typeof expireDays !== 'undefined') {
        let d = new Date();
        d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
        expires = "expires=" + d.toUTCString();
    }
    if(window.$envConfig.APP_ENV === 'dev') {
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    } else {
        document.cookie = name + "=" + value + ";secure;" + expires + ";path=/";
    }
};

export const getCookie = function (cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
};

export const deleteCookie = function (cname) {
    document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export default {setCookie, getCookie,deleteCookie};