import $ from "jquery";
import "jquery-ui";
import "jquery-ui/ui/widgets/autocomplete";
import {_elementUtil} from "./element-util";
import {getzIndex, serviceHeaderBuilder} from "./helper";
import {initError} from "./handleError";

/****
 SEARCH SERVICE
 data-autocomplete="/api/type/iata-airport/list"
 data-autocomplete-type="service"
 data-autocomplete-method="POST"
 data-autocomplete-columns="code,name"
 data-autocomplete-search="name"
 data-autocomplete-order="name"
 data-autocomplete-order-type="desc"
 data-autocomplete-label="[#code#] #name#"
 data-autocomplete-value="#code#"
 data-autocomplete-target="#hiddenElt"
 data-autocomplete-limit="10"
 data-autocomplete-message="No matches found"

 LIST SERVICE
 data-autocomplete="/api/hotel/list"
 data-autocomplete-type="static"
 data-autocomplete-method="POST"
 data-autocomplete-response-data="hotels"
 data-autocomplete-columns="id,code,name"
 data-autocomplete-search="name"
 data-autocomplete-order="name"
 data-autocomplete-order-type="desc"
 data-autocomplete-label="[#code#] #name#"
 data-autocomplete-value="#id#"
 data-autocomplete-target="#hotelid"
 data-autocomplete-limit="10"
 data-autocomplete-message="No matches found"

 AUTOCOMPLETE SERVICE
 data-autocomplete="/api/autocomplete/flight-destination"
 data-autocomplete-type="autocomplete"
 data-autocomplete-method="POST"
 data-autocomplete-request-data='{"onlyAirports" : true,"onlyCities" : true}'
 data-autocomplete-response-data="flightDestinations"
 data-autocomplete-columns="code,name,airports"
 data-autocomplete-label="(#code#) #name# All Airports"
 data-autocomplete-value="#code#"
 data-autocomplete-sub-key="airports"
 data-autocomplete-sub-columns="code,name"
 data-autocomplete-sub-label="(#code#) #name# Airport"
 data-autocomplete-sub-value="#code#"
 data-autocomplete-target="#airportv2"
 data-autocomplete-limit="10"
 data-autocomplete-message="No matches found"
 */
export const autoCompleteUtil = (element) => {
    let autoComplete = {
        element: element,
        parent: '',
        type: null,
        url: null,
        method: null,
        start: 0,
        limit: 10,
        minLength: 2,
        order: 0,
        orderName: null,
        orderType: 'asc',
        columns: [],
        searchColumns: [],
        data: [],
        jsonData: [],
        requestData: null,
        label: null,
        value: null,
        sub: {
            columns: [],
            key: null,
            label: null,
            value: null,
        },
        valueElement: null,
        responseData: null,
        static: false,
        message: 'No matches found',
        exclude: [],
        excludeField: null,
        button: null,
        memory: {
            label: null,
            value: null,
        }
    };

    autoComplete.set = {
        isModal: function () {
            if (autoComplete.element.parents(".modal").length > 0) {
                autoComplete.parent = "#" + autoComplete.element.parents(".modal").attr("id") + " ";
            }
        },
        columns: function () {
            let columns = autoComplete.element.attr('data-autocomplete-columns').split(",");
            $.each(columns, function (index, column) {
                autoComplete.columns.push({"data": column});
            });
        },
        searchColumns: function () {
            if (typeof autoComplete.element.attr('data-autocomplete-search') !== 'undefined') {
                let columns = autoComplete.element.attr('data-autocomplete-search').split(",");
                $.each(columns, function (index, column) {
                    autoComplete.searchColumns.push({
                        "field": column,
                        "value": autoComplete.request.value(),
                        "type": "LIKE"
                    });
                });
            }
        },
        order: function () {
            if (typeof autoComplete.element.attr('data-autocomplete-order') !== 'undefined') {
                autoComplete.orderName = autoComplete.element.attr('data-autocomplete-order');
                $.each(autoComplete.columns, function (index, column) {
                    if (column === autoComplete.element.attr('data-autocomplete-order')) {
                        autoComplete.order = index;
                        return true;
                    }
                });
            }
        },
        orderBy: function () {
            if (typeof autoComplete.element.attr('data-autocomplete-order-type') !== 'undefined') {
                autoComplete.orderType = autoComplete.element.attr('data-autocomplete-order-type');
            }
        },
        limit: function () {
            if (typeof autoComplete.element.attr('data-autocomplete-limit') !== 'undefined') {
                autoComplete.orderType = parseInt(autoComplete.element.attr('data-autocomplete-limit'));
            }
        },
        url: function () {
            autoComplete.url = autoComplete.element.attr("data-autocomplete");
        },
        method: function () {
            autoComplete.method = typeof autoComplete.element.attr("data-autocomplete-method") !== 'undefined' ? autoComplete.element.attr("data-autocomplete-method") : 'POST';
        },
        label: function () {
            autoComplete.label = autoComplete.element.attr("data-autocomplete-label");
        },
        value: function () {
            autoComplete.value = autoComplete.element.attr("data-autocomplete-value");
        },
        valueElement: function () {
            autoComplete.valueElement = typeof autoComplete.element.attr("data-autocomplete-target") !== 'undefined' ? $(autoComplete.parent + autoComplete.element.attr("data-autocomplete-target")) : autoComplete.element;
        },
        responseData: function () {
            autoComplete.responseData = typeof autoComplete.element.attr("data-autocomplete-response-data") !== 'undefined' ? autoComplete.element.attr("data-autocomplete-response-data") : null;
        },
        message: function () {
            if (typeof autoComplete.element.attr('data-autocomplete-message') !== 'undefined') {
                autoComplete.message = parseInt(autoComplete.element.attr('data-autocomplete-message'));
            }
        },
        type: function () {
            if (typeof autoComplete.element.attr('data-autocomplete-type') !== 'undefined') {
                autoComplete.type = autoComplete.element.attr('data-autocomplete-type');
            }
        },
        requestData: function () {
            if (typeof autoComplete.element.attr('data-autocomplete-request-data') !== 'undefined') {
                autoComplete.requestData = JSON.parse(autoComplete.element.attr('data-autocomplete-request-data'));
            }
        },
        subData: function () {
            if (typeof autoComplete.element.attr('data-autocomplete-sub-key') !== 'undefined') {
                let columns = autoComplete.element.attr('data-autocomplete-sub-columns').split(",");
                $.each(columns, function (index, column) {
                    autoComplete.sub.columns.push({"data": column});
                });
                autoComplete.sub.key = autoComplete.element.attr('data-autocomplete-sub-key');
                autoComplete.sub.label = autoComplete.element.attr('data-autocomplete-sub-label');
                autoComplete.sub.value = autoComplete.element.attr('data-autocomplete-sub-value');
            }
        },
        exclude: function () {
            if (typeof autoComplete.element.attr('data-autocomplete-exclude') !== 'undefined') {
                autoComplete.exclude = autoComplete.element.attr('data-autocomplete-exclude').split(",");
                autoComplete.excludeField = autoComplete.element.attr('data-autocomplete-exclude-field');
            }
        },
        button: function () {
            if (typeof autoComplete.element.attr('data-autocomplete-button') !== 'undefined') {
                autoComplete.button = $(autoComplete.element.attr('data-autocomplete-button'));
            }
        },
        minLength: function () {
            if (typeof autoComplete.element.attr('data-autocomplete-min-length') !== 'undefined') {
                autoComplete.minLength = parseInt(autoComplete.element.attr('data-autocomplete-min-length'));
            }
        },
        init: function () {
            autoComplete.set.isModal();
            autoComplete.set.columns();
            autoComplete.set.searchColumns();
            autoComplete.set.url();
            autoComplete.set.method();
            autoComplete.set.order();
            autoComplete.set.orderBy();
            autoComplete.set.label();
            autoComplete.set.value();
            autoComplete.set.valueElement();
            autoComplete.set.responseData();
            autoComplete.set.type();
            autoComplete.set.requestData();
            autoComplete.set.subData();
            autoComplete.set.exclude();
            autoComplete.set.button();
            autoComplete.set.minLength();
            autoComplete.set.message();
        }
    };

    autoComplete.request = {
        value: function () {
            return autoComplete.element.val();
        },
        data: function () {
            if (autoComplete.type === 'autocomplete') {
                let data = {
                    term: autoComplete.request.value(),
                    limit: autoComplete.limit,
                };
                if (autoComplete.requestData !== null) {
                    return JSON.stringify(Object.assign(data, autoComplete.requestData));
                }

                return JSON.stringify(data);
            } else if (autoComplete.type === 'service') {
                autoComplete.set.searchColumns();
                return JSON.stringify({
                    "columns": autoComplete.columns,
                    "order": [
                        {
                            "column": autoComplete.order,
                            "dir": autoComplete.orderType,
                        }
                    ],
                    "start": autoComplete.start,
                    "length": autoComplete.limit,
                    "responseType": "dataTable",
                    "searchColumns": autoComplete.searchColumns,
                });
            }

        },
        staticData: function () {
            return "";
        },
        ajax: function (callBack) {
            _elementUtil.addLoader(autoComplete.element);
            $.ajax({
                method: autoComplete.method,
                url: autoComplete.url,
                data: autoComplete.static === true ? autoComplete.request.staticData() : autoComplete.request.data(),
                dataType: "json",
                beforeSend: serviceHeaderBuilder,
                success: function (response, textStatus, xhr) {
                    _elementUtil.removeLoader(autoComplete.element);
                    autoComplete.response.init(response, status, xhr, callBack);
                },
                error: function (xhr) {
                    initError(xhr);
                }
            });
        },
        init: function (request, callBack) {
            autoComplete.request.ajax(callBack);
        }
    };

    autoComplete.response = {
        render: function (data, callBack) {
            let result = [];
            for (let i = 0; i < data.length; i++) {
                let f = {isSub: false, isSubExists: false};
                let item = data[i];
                for (let c = 0; c < autoComplete.columns.length; c++) {
                    $.each(item, function (index, field) {
                        if (index === autoComplete.columns[c].data) {
                            f[index] = field;
                            return false;
                        }
                    });
                }
                f.isSubExists = autoComplete.sub.key !== null && typeof f[autoComplete.sub.key] !== 'undefined';

                if (!autoComplete.response.exclude(f)) {
                    result.push(autoComplete.prop.render(f));
                }
                if (autoComplete.sub.key !== null) {
                    if (typeof f[autoComplete.sub.key] !== 'undefined') {
                        $.each(f[autoComplete.sub.key], function (subIndex, subItem) {
                            let f = {isSub: true};
                            $.each(subItem, function (subItemIndex, subItemField) {
                                for (let i = 0; i < autoComplete.sub.columns.length; i++) {
                                    if (subItemIndex === autoComplete.sub.columns[i].data) {
                                        f[subItemIndex] = subItemField;
                                        break;
                                    }
                                }
                            });
                            if (!autoComplete.response.exclude(f)) {
                                result.push(autoComplete.prop.renderSub(f));
                            }
                        });
                    }
                }
            }

            if (result.length === 0) {
                result.push({
                    label: autoComplete.message,
                    value: 'no_matches'
                });
            }
            callBack(result);
        },
        staticRender: function (response, callBack) {
            if (typeof response.data !== 'undefined') {
                if (typeof response.data[autoComplete.responseData] !== 'undefined') {

                    autoComplete.jsonData = [];
                    let sorted = [];
                    let data = response.data[autoComplete.responseData];

                    $.each(data, function (index, data) {
                        sorted.push({"order": data[autoComplete.orderName], "index": index});
                    });

                    if (autoComplete.orderName !== null) {
                        sorted.sort(function (a, b) {
                            return a.order.localeCompare(b.order);
                        });

                        if (autoComplete.orderType === "desc") {
                            sorted.reverse();
                        }
                    }

                    for (let i = 0; i < sorted.length; i++) {
                        let f = {};
                        let item = data[sorted[i].index];
                        for (let c = 0; c < autoComplete.columns.length; c++) {
                            $.each(item, function (index, field) {
                                if (index === autoComplete.columns[c].data) {
                                    f[index] = field;
                                    return false;
                                }
                            });
                        }

                        autoComplete.jsonData.push(autoComplete.prop.render(f));
                    }

                    autoComplete.staticData.search(callBack);
                }
            }
        },
        exclude: function (data) {
            let isExclude = false;
            if (autoComplete.excludeField !== null) {
                let value = data[autoComplete.excludeField].toString();
                isExclude = autoComplete.exclude.find(function (item) {
                    return item !== value ? false : true;
                });
            }
            return isExclude;
        },
        init: function (response, status, xhr, callBack) {
            if (autoComplete.type === 'static') {
                autoComplete.response.staticRender(response, callBack);
            } else if (autoComplete.type === 'service') {
                if (typeof response.aaData !== 'undefined') {
                    autoComplete.response.render(response.aaData, callBack);
                }
            } else if (autoComplete.type === 'autocomplete') {
                let spl = autoComplete.responseData.split(".");
                let responseData = typeof response.data[autoComplete.responseData] !== 'undefined' ? response.data[autoComplete.responseData] : null;
                if (spl.length > 1) {
                    responseData = typeof response.data[spl[0]][spl[1]] !== 'undefined' ? response.data[spl[0]][spl[1]] : null;
                }
                if (responseData !== null) {
                    autoComplete.response.render(responseData, callBack);
                }
            }

        }
    };

    autoComplete.staticData = {
        search: function (callBack) {
            let searched = [];
            let searchValue = autoComplete.element.val().toUpperCase();
            let searchValueLength = searchValue.length;
            $.each(autoComplete.jsonData, function (index, json) {
                $.each(json.data, function (subIndex, data) {
                    let found = false;
                    $.each(autoComplete.searchColumns, function (searchIndex, searchField) {
                        if (searchField.field === subIndex) {
                            if (data.toUpperCase().substr(0, searchValueLength) === searchValue) {
                                found = true;
                            }
                            return true;
                        }
                    });
                    if (found) {
                        if (!autoComplete.response.exclude(json.data)) {
                            searched.push(json);
                        }

                    }
                });
            });

            if (searched.length === 0) {
                searched.push({
                    label: autoComplete.message,
                    value: 'no_matches',
                    data: {},
                });
            }

            callBack(searched);
        },
        load: function (request, callBack) {
            autoComplete.request.init(request, callBack);
        },
        init: function (request, callBack) {
            if (autoComplete.jsonData.length === 0) {
                autoComplete.static = true;
                autoComplete.staticData.load(request, callBack);
            } else {
                autoComplete.staticData.search(callBack);
            }
        }
    };

    autoComplete.prop = {
        render: function (data) {
            let label = autoComplete.label;
            let value = autoComplete.value;
            for (let i = 0; i < autoComplete.columns.length; i++) {
                let field = autoComplete.columns[i].data;
                let rpc = new RegExp("#" + field + "#", "gmi");

                label = label.replace(rpc, data[field]);
                value = value.replace(rpc, data[field]);
            }

            if (typeof data.isSubExists !== 'undefined') {
                let rpc = new RegExp(/#isSubExists:(.*?):(.*?)#/gm);
                let m = rpc.exec(label);
                if (m !== null && m.length > 0) {
                    if (data.isSubExists) {
                        label = label.replace(m[0], m[1]);
                    } else {
                        label = label.replace(m[0], m[2]);
                    }
                }
                m = rpc.exec(value);
                if (m !== null && m.length > 0) {
                    if (data.isSubExists) {
                        value = value.replace(m[0], m[1]);
                    } else {
                        value = value.replace(m[0], m[2]);
                    }
                }

            }


            return {
                label: label,
                value: value,
                data: data,
            };
        },
        renderSub: function (data) {
            let label = autoComplete.sub.label;
            let value = autoComplete.sub.value;

            for (let i = 0; i < autoComplete.sub.columns.length; i++) {
                let field = autoComplete.sub.columns[i].data;
                let rpc = new RegExp("#" + field + "#", "gmi");

                label = label.replace(rpc, data[field]);
                value = value.replace(rpc, data[field]);
            }

            return {
                label: label,
                value: value,
                data: data,
            };
        },
        params: {
            source: function (request, callBack) {
                let zIndex = getzIndex();
                $(".ui-autocomplete").attr('style', "z-index:" + zIndex + ";display:none;");
                if (autoComplete.type === 'static') {
                    autoComplete.staticData.init(request, callBack);
                } else {
                    autoComplete.request.init(request, callBack);
                }
            },
            minLength: autoComplete.minLength,
            select: function (event, ui) {
                if (ui.item.value !== "no_matches") {
                    autoComplete.valueElement.val(ui.item.value);
                    autoComplete.element.val(ui.item.label);
                    autoComplete.element.blur();
                }
                return false;
            }
        },
        run: function () {
            autoComplete.prop.params.minLength = autoComplete.minLength;
            autoComplete.element.autocomplete(autoComplete.prop.params).data("ui-autocomplete")._renderItem = function (ul, item) {
                return $('<li class="autocomplete-custom ' + (typeof item.data !== 'undefined' && item.data.isSub ? 'sub-item' : '') + ' ' + (typeof item.data !== 'undefined' && typeof item.data.isSubExists !== 'undefined' && item.data.isSubExists === true ? 'ui-state-disabled' : '') + '" ' + (typeof item.data !== 'undefined' && typeof item.data.isSubExists !== 'undefined' && item.data.isSubExists === true ? 'disabled' : '') + '></li>').data('item.autocomplete', item).html(item.label).appendTo(ul);
            };
        },
        addExclude: function () {
            autoComplete.exclude.push(autoComplete.valueElement.val());
            autoComplete.valueElement.val("");
            autoComplete.element.val("");
        },
        init: function () {
            autoComplete.prop.run();
            autoComplete.element.on("keyup", function (event) {
                let charCode = (event.which) ? event.which : event.keyCode;
                if (charCode === 8) {
                    autoComplete.valueElement.val("");
                }
            });

            /*
            if(autoComplete.button !== null) {
                autoComplete.button.on("click", function () {
                    autoComplete.prop.addExclude();
                });
            }
            */

            autoComplete.element.on("blur", function () {
                if (autoComplete.valueElement.val() === "") {
                    autoComplete.element.val("");
                }
            });
            autoComplete.element.on("focus", function () {
                autoComplete.set.exclude();
            });

            /*autoComplete.element.on("focus", function () {
                autoComplete.memory.label = autoComplete.element.val();
                autoComplete.memory.value = autoComplete.valueElement.val();
                autoComplete.valueElement.val("");
                autoComplete.element.val("");
            });
            autoComplete.element.on("blur", function () {
                if (autoComplete.element.val() === "" && autoComplete.memory.label !== "") {
                    autoComplete.valueElement.val(autoComplete.memory.value);
                    autoComplete.element.val(autoComplete.memory.label);
                }
            });*/
        }
    };

    autoComplete.set.init();
    autoComplete.prop.init();
};

export const autoCompleteCallBack = (response, element) => {
    let elt = $(element.attr('data-autocomplete-element'));
    let eltCode = $(elt.attr('data-autocomplete-target'));
    let exclude = elt.attr('data-autocomplete-exclude') + ',' + eltCode.val();
    elt.attr('data-autocomplete-exclude', exclude);
    elt.val("");
    eltCode.val("");
};

export default  {
    autoCompleteUtil,autoCompleteCallBack
}


