import $ from "jquery";
import "bootstrap";
import {pageReload} from "./helper";
import i18n from "../plugins/vue-i18n";
import {select2Create} from "./select2-util";
import {dateUtil} from "./datepicker-util";
import {numberFormatUtil} from "./number-format-util";
import {loadDataTables, loadDataTablesHtml} from "./data-table";
import {autoCompleteUtil} from "./auto-complate-util";
import {responsiveTable} from "./responsive-table-util";
import {dateFormat} from "./data-type-dateformat";
import {getElementByAttr, setElementUtil} from "./element-util";
import {removeValidation} from "./removeValidation";

export const createModal = (url, size, direction) => {
    let id = "_modal_" + Date.now();
    let modalClass = "modal fade " + (typeof direction !== 'undefined' ? direction : "");
    let modalDialog = "modal-dialog";
    let modalContent = "modal-content";
    if (typeof size !== 'undefined') {
        if (size === null) {
            size = "";
        }
        if (size === 'modal-xxl') {
            modalDialog = "modal-dialog modal-dialog-xxl";
            modalContent = "modal-content modal-content-xxl";
        } else {
            modalDialog = modalDialog + " " + size;
        }

    }
    $("<div/>", {
        id: id,
        class: modalClass,
        tabindex: -1,
        role: "dialog",
        'data-keyboard': 'false',
        'data-modal-ajax-url': typeof url !== 'undefined' ? url : null,
    }).append(
        $("<div/>", {
            class: modalDialog,
        }).append(
            $("<div/>", {
                class: modalContent,
            }))
    ).appendTo($("body"));
    return id;
};

export const createModalContent = (content, size, direction) => {

    let id = "_modalHtml_" + Date.now();
    let modalClass = "modal fade " + (typeof direction !== 'undefined' ? direction : "");
    let modalDialog = "modal-dialog";
    let modalContent = "modal-content";
    if (typeof size !== 'undefined') {
        if (size === null) {
            size = "";
        }
        if (size === 'modal-xxl') {
            modalDialog = "modal-dialog modal-dialog-xxl";
            modalContent = "modal-content modal-content-xxl";
        } else {
            modalDialog = modalDialog + " " + size;
        }

    }
    $("<div/>", {
        id: id,
        class: modalClass,
        tabindex: -1,
        role: "dialog",
    }).append(
        $("<div/>", {
            class: modalDialog,
        }).append(
            $("<div />", {
                html: content,
                class: modalContent,
            }))
    ).appendTo($("body"));
    return id;
};

export const loadModalFunctionsCall = (modalId) => {
    let modal = $(modalId);

    modal.find("[data-select=true]").each(function () {
        select2Create($(this));
    });
    /* modal.find("[data-repeater=true]").each(function () {
         repeaterCreater($(this));
     });*/
    /*if (modal.find("[data-type=phone]").length > 0) {
        getPhoneListUtil(modal);
    }*/
    /*modal.find('input[type="radio"][data-validation-control=true]:checked').each(function () {
        validationColtrol($(this));
    });*/
    /*modal.find('[data-editor=true]').each(function () {
        editorUtil($(this));
    });*/
    modal.find('[data-type=date]').each(function () {
        dateUtil($(this));
    });

    modal.find('[data-type=decimal],[data-type=money],[data-type=number]').each(function () {
        numberFormatUtil($(this));
    });

    /*modal.find("[data-select-to-select]").each(function () {
        selectToSelect($(this), "load");
    });*/

    /*modal.find("[data-type=time]").each(function () {
        timeUtil($(this));
    });*/

    /*
    modal.find("[data-add-new-modal-url]").each(function (e) {
        addNewCreate($(this));
    });
     */

    /*modal.find("[data-formula=true]").each(function () {
        _formulaUtil = formulaUtil($(this));
    });*/

    /*modal.find("[data-table-type=datatable]").each(function () {
        window.allDatableObj["#" + $(this).attr("id")] = loadDataTables($(this));
    });

    $("[data-table-type=datatablehtml]").each(function () {
        window.allDatableObj["#" + $(this).attr("id")] = loadDataTablesHtml($(this));
    });*/

    /*modal.find('[data-type=alphaNumeric]').each(function () {
        stringFormatUtil($(this));
    });*/

    /*modal.find('[data-log-viewer=true]').each(function () {
        logViewer($(this));
    });*/

    modal.find("[data-autocomplete]").each(function () {
        autoCompleteUtil($(this));
    });

    /*modal.find("[data-colorpicker=true]").each(function () {
        colorPickerUtil($(this));
    });*/

    /*modal.find("[data-config-element]").each(function () {
        configUtil($(this));
    });*/

    /*modal.find("[data-charge-table=true]").each(function () {
        changeTableUtil($(this));
    });*/

    /*modal.find('[data-check-control-all]').each(function () {
        checkbox_control($(this));
    });*/

    /*modal.find('[data-checkbox-search]').each(function () {
        checkbox_search($(this));
    });*/

    /*modal.find("[data-form]").each(function () {
        formUtil($(this));
    });*/

    /*modal.find("[data-image=true]").each(function () {
        imageUtil($(this));
    });*/

    /*modal.find("[data-photo-loader]").each(function () {
        photoUtil($(this));
    });*/

    modal.find("table.responsive-table").each(function () {
        responsiveTable($(this));
    });

    modal.find('[data-type=dateFormat]').each(function () {
        dateFormat($(this));
    });

    /*modal.find('[data-type=reset]').each(function () {
        resetFormUtil($(this));
    });*/

    /*modal.find('[data-tree-button]').each(function () {
        jsTreeEvent($(this));
    });*/

    /*modal.find('[data-radio-prop-click=guestsIsLeader]').each(function () {
        guestsIsLeader($(this));
    });*/

    $('*').popover('hide');

    modal.find("[data-method][data-method!=inline-edit]").each(function () {
        if (typeof $(this).attr("data-method-autorun") !== 'undefined' && $(this).attr('data-method-autorun') === 'true') {
            $(this).trigger("click");
        }
    });

    /*modal.find('[data-password-match]').each(function () {
        passwordMatchUtil($(this));
    });*/

    /*modal.find('[data-password-reset]').each(function () {
        passwordResetUtil($(this));
    });*/

    /*modal.find('[data-password-validate]').each(function () {
        passwordValidateUtil($(this));
    });*/

    /*modal.find('[data-change-stay-details]').each(function () {
        changeStayDetailsUtil($(this));
    });*/

    /*modal.find('[data-print-prop-click]').each(function () {
        printUtil($(this));
    });*/

    /*modal.find("[data-alert-action=true]").each(function (e) {
        alertActionUtil($(this));
    });*/

    /*modal.find('[data-sort]').each(function () {
        sortUtil($(this));
    });*/

    /*modal.find('[data-table-search]').each(function () {
        tableSearchUtil($(this));
    });*/

    /*let renderSelectElements = getElementByAttr("data-render-select-", modalId);
    selectRenderUtil(renderSelectElements);*/

    let propElements = getElementByAttr("data-prop-", modalId);
    setElementUtil(propElements, modalId);

    modal.on('change', 'input[type="radio"][name="hotelierSupplier"]', function (e) {
        $(this).prop("checked", true);
        if (this.value === 'select') {
            $('#selectHotelierSupplier').removeClass('hidden');
            $('#newHotelierSupplier').addClass('hidden');
        } else {
            $('#selectHotelierSupplier').addClass('hidden');
            $('#newHotelierSupplier').removeClass('hidden');
        }
    });

    /*modal.find('[data-onload]').each(function () {
        onloadUtil($(this));
    });*/

    if (typeof modal.attr("data-modal-parent-id") !== 'undefined') {
        modal.find('[data-dismiss=modal]').each(function () {
            $(this).on("click", function () {
                let parentModal = $("#" + modal.attr("data-modal-parent-id"));
                parentModal.modal("show");
                parentModal.css("overflow", "auto");
            });
        });
    }
};

export const loadModalFunctions = (modalId, isShow) => {
    let modal = $(modalId);
    let show = !(typeof isShow !== 'undefined' && isShow === false);
    if (show) {
        modal.modal("show");
    }

    $(modalId + ' .modal-content').html('<div class="text-center m-3">Loading...</div>').load(
        encodeURI(modal.attr('data-modal-ajax-url')),
        function (response, status, xhr) {
            if (xhr.status === 200) {
                if ($(modalId + ' .modal-content').find("#loginContainer").length > 0) {
                    let message = $(modalId + ' .modal-content').find("#loginContainer").attr('data-message');
                    modal.remove();
                    pageReload(message);
                } else {
                    loadModalFunctionsCall(modalId, isShow);
                }
            } else {
                const regex = new RegExp(/<div class="m-error_container">(.*)<\/div>/gm);
                let str = "";
                for (let i = 0; i < xhr.responseText.length; i++) {
                    if (xhr.responseText.charCodeAt(i) !== 10) {
                        str += xhr.responseText.charAt(i);
                    }
                }
                let resHtml = regex.exec(str);
                if (resHtml !== null && resHtml.length > 0) {
                    $(modalId + ' .modal-content').html('<div class="p-20px">' + resHtml[0].replace('</pre>', '</div>').replace('<pre>', '<div>').replace('FILE :', '<br>FILE :').replace('LINE :', '<br>LINE :') + '</div>');
                } else {
                    const regex2 = new RegExp(/<span id="error-message">(.*)<\/span>/gm);
                    resHtml = regex2.exec(str);
                    if (resHtml !== null && resHtml.length > 0) {
                        $(modalId + ' .modal-content').html('<div class="p-20px">' + resHtml[0].replace('</pre>', '</div>').replace('<pre>', '<div>').replace('FILE :', '<br>FILE :').replace('LINE :', '<br>LINE :') + '</div>');
                    } else {
                        $(modalId + ' .modal-content').html('<div class="text-center mtb-20px"><h2 class="fs-18">' + xhr.status + ' ' + i18n.messages.internalServerError + '</h2></div>');
                    }
                }
            }
        });
};

export const postModalFunctions = (modalId, isShow, query) => {
    let modal = $(modalId);
    let show = !(typeof isShow !== 'undefined' && isShow === false);
    if (show) {
        modal.modal("show");
    }

    $(modalId + ' .modal-content').html('<div class="text-center m-3">Loading...</div>');
    $.post(
        encodeURI(modal.attr('data-modal-ajax-url')),
        query,
        function (response, status, xhr) {
            if (xhr.status === 200) {
                $(modalId + ' .modal-content').html(response);
                if ($(modalId + ' .modal-content').find("#loginContainer").length > 0) {
                    let message = $(modalId + ' .modal-content').find("#loginContainer").attr('data-message');
                    modal.remove();
                    pageReload(message);
                } else {
                    loadModalFunctionsCall(modalId, isShow);
                }
            } else {
                const regex = new RegExp(/<div class="m-error_container">(.*)<\/div>/gm);
                let str = "";
                for (let i = 0; i < xhr.responseText.length; i++) {
                    if (xhr.responseText.charCodeAt(i) !== 10) {
                        str += xhr.responseText.charAt(i);
                    }
                }
                let resHtml = regex.exec(str);
                if (resHtml !== null && resHtml.length > 0) {
                    $(modalId + ' .modal-content').html('<div class="p-20px">' + resHtml[0].replace('</pre>', '</div>').replace('<pre>', '<div>').replace('FILE :', '<br>FILE :').replace('LINE :', '<br>LINE :') + '</div>');
                } else {
                    const regex2 = new RegExp(/<span id="error-message">(.*)<\/span>/gm);
                    resHtml = regex2.exec(str);
                    if (resHtml !== null && resHtml.length > 0) {
                        $(modalId + ' .modal-content').html('<div class="p-20px">' + resHtml[0].replace('</pre>', '</div>').replace('<pre>', '<div>').replace('FILE :', '<br>FILE :').replace('LINE :', '<br>LINE :') + '</div>');
                    } else {
                        $(modalId + ' .modal-content').html('<div class="text-center mtb-20px"><h2 class="fs-18">' + xhr.status + ' ' + LANGUAGE.internalServerError + '</h2></div>');
                    }
                }
            }

        }).fail(function (xhr) {
        const regex = new RegExp(/<div class="m-error_container">(.*)<\/div>/gm);
        let str = "";
        for (let i = 0; i < xhr.responseText.length; i++) {
            if (xhr.responseText.charCodeAt(i) !== 10) {
                str += xhr.responseText.charAt(i);
            }
        }
        let resHtml = regex.exec(str);
        if (resHtml !== null && resHtml.length > 0) {
            $(modalId + ' .modal-content').html('<div class="p-20px">' + resHtml[0].replace('</pre>', '</div>').replace('<pre>', '<div>').replace('FILE :', '<br>FILE :').replace('LINE :', '<br>LINE :') + '</div>');
        } else {
            const regex2 = new RegExp(/<span id="error-message">(.*)<\/span>/gm);
            resHtml = regex2.exec(str);
            if (resHtml !== null && resHtml.length > 0) {
                $(modalId + ' .modal-content').html('<div class="p-20px">' + resHtml[0].replace('</pre>', '</div>').replace('<pre>', '<div>').replace('FILE :', '<br>FILE :').replace('LINE :', '<br>LINE :') + '</div>');
            } else {
                $(modalId + ' .modal-content').html('<div class="text-center mtb-20px"><h2 class="fs-18">' + xhr.status + ' ' + LANGUAGE.internalServerError + '</h2></div>');
            }
        }
    });
};

export const loadModalFunctionsHtml = (modalId, isShow) => {
    let modal = $(modalId);
    let show = !(typeof isShow !== 'undefined' && isShow === false);
    if (show) {
        modal.modal("show");
    }
    loadModalFunctionsCall(modalId, isShow);
};

export const openModal = (element, url, size, method, query) => {
    if (typeof method === 'undefined' || method === null) {
        method = 'load';
    }
    if (typeof query === 'undefined' || query === null) {
        query = {};
    }
    let isContinue = true;
    if (typeof element.attr("data-modal-before-call") !== 'undefined') {
        if (typeof window[element.attr("data-modal-before-call")] !== 'undefined') {
            isContinue = window[element.attr("data-modal-before-call")](element);
        }
    }

    if (isContinue) {
        let direction = "";
        if (typeof element.attr("data-modal-direction") !== 'undefined') {
            direction = element.attr("data-modal-direction");
        }
        if (typeof element.attr("data-modal-element-id") === 'undefined') {
            element.attr("data-modal-element-id", "#" + createModal(typeof element.attr("data-modal-href") !== 'undefined' ? renderModalUrl(element.attr("data-modal-href")) : url, size, direction));
        }

        if (hasScrollBar()) {
            getScrollBarWidth();
            $("body").addClass("hidden-overflow").addClass("mr-" + window.scrollBarWith + "px").addClass("pr-0px");
        } else {
            $("body").addClass("hidden-overflow");
        }

        let modalId = element.attr("data-modal-element-id");

        if (typeof element.parents(".modal") !== 'undefined') {
            if (typeof element.attr('data-modal-destroy') === 'undefined') {
                $(modalId).attr("data-modal-parent-id", element.parents(".modal").attr("id"));
            }
            element.parents(".modal").modal("hide");

            if (typeof element.attr('data-modal-from') !== 'undefined') {
                $(modalId).attr("data-modal-from", element.attr('data-modal-from'));
                $(modalId).attr("data-modal-from-id", element.attr('id'));
            }
        }

        if (typeof element.parents(".collapse") !== 'undefined') {
            $(modalId).attr("data-toggle-parent-id", element.parents(".collapse").attr("id"));
        }

        $(modalId).attr("data-modal-ajax-url", typeof element.attr("data-modal-href") !== 'undefined' ? renderModalUrl(element.attr("data-modal-href")) : url);
        if (typeof size !== 'undefined' && size !== null) {
            $(modalId + ' .modal-dialog').removeClass("modal-xl modal-xxl modal-md modal-sm modal-xs").addClass(size);
        } else {
            $(modalId + ' .modal-dialog').removeClass("modal-xl modal-xxl modal-md modal-sm modal-xs").addClass("modal-md");
        }

        if (method === 'load') {
            $(modalId).attr('data-modal-type', 'load');
            loadModalFunctions(modalId, true);
        } else if (method === 'post') {
            $(modalId).attr('data-modal-type', 'post');
            $(modalId).attr('data-modal-query', JSON.stringify(query));
            postModalFunctions(modalId, true, query);
        }

        $(modalId).on('hidden.bs.modal', function () {

            if ($('.modal.show').length === 0) {
                if (hasScrollBar()) {
                    $("body").removeClass("hidden-overflow").removeClass("mr-" + window.scrollBarWith + "px").removeClass("pr-0px");
                } else {
                    $("body").removeClass("hidden-overflow");
                }

            }

            $(modalId).find('[aria-describedby]').each(function () {
                removeValidation($(this));
            });
        });

        $(modalId).on('shown.bs.modal', function () {
            $(document).off('focusin.modal');
        });
    }
};

export const openModalForHtml = (html, size) => {
    let modalId = "#" + createModalContent(html, size);
    let modal = $(modalId);
    if (hasScrollBar()) {
        getScrollBarWidth();
        $("body").addClass("hidden-overflow").addClass("mr-" + window.scrollBarWith + "px").addClass("pr-0px");
    } else {
        $("body").addClass("hidden-overflow");
    }

    if (typeof size !== 'undefined' && size !== null) {
        $(modalId + ' .modal-dialog').removeClass("modal-xl modal-xxl modal-md modal-sm modal-xs").addClass(size);
    } else {
        $(modalId + ' .modal-dialog').removeClass("modal-xl modal-xxl modal-md modal-sm modal-xs").addClass("modal-md");
    }
    loadModalFunctionsHtml(modalId, true);

    modal.on('hidden.bs.modal', function () {
        if ($('.modal.show').length === 0) {
            if (hasScrollBar()) {
                $("body").removeClass("hidden-overflow").removeClass("mr-" + window.scrollBarWith + "px").removeClass("pr-0px");
            } else {
                $("body").removeClass("hidden-overflow");
            }
        }

        modal.find('[aria-describedby]').each(function () {
            removeValidation($(this));
        });
    });

    modal.on('shown.bs.modal', function () {
        $(document).off('focusin.modal');
    });

    return modal;
};

export const openParentModal = (parentModalId, isShow) => {
    if ($(parentModalId).length > 0) {
        let parentModalType = 'load';
        let parentModalQuery = null;
        if (typeof $(parentModalId).attr('data-modal-type') !== 'undefined') {
            parentModalType = $(parentModalId).attr('data-modal-type');
            if (typeof $(parentModalId).attr('data-modal-query') !== 'undefined') {
                parentModalQuery = JSON.parse($(parentModalId).attr('data-modal-query'));
                if (typeof parentModalQuery.firstValidation !== 'undefined' && parentModalQuery.firstValidation === 'true') {
                    parentModalQuery.firstValidation = false;
                }
            }
        }
        if (parentModalType === 'load') {
            loadModalFunctions(parentModalId, isShow);
        } else if (parentModalType === 'post') {
            postModalFunctions(parentModalId, isShow, parentModalQuery);
        }
    }
}

export const hasScrollBar = () => {
    return $(document).height() > $(window).height();
};

export const getScrollBarWidth = () => {
    let inner = document.createElement('p');
    inner.style.width = "100%";
    inner.style.height = "200px";

    let outer = document.createElement('div');
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild(inner);

    document.body.appendChild(outer);
    let w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    let w2 = inner.offsetWidth;
    if (w1 === w2) w2 = outer.clientWidth;

    document.body.removeChild(outer);

    window.scrollBarWith = (w1 - w2);
    return scrollBarWith;
};

export const renderModalUrl = (url) => {
    let regex = new RegExp(/##(.*?)##/gm);
    let m;
    let replaceData = [];

    while ((m = regex.exec(url)) !== null) {
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }

        for (let i1 = 0; i1 < m.length / 2; i1++) {
            replaceData.push({value: m[i1], key: m[i1 + 1]});
        }
    }

    if (replaceData.length > 0) {
        for (let i = 0; i < replaceData.length; i++) {
            url = url.replace(replaceData[i].value, $('#' + replaceData[i].key).val());
        }
    }
    return url.replace(/ /gi, '%20');
}

export default {
    createModal,
    createModalContent,
    loadModalFunctionsCall,
    loadModalFunctions,
    postModalFunctions,
    loadModalFunctionsHtml,
    openModal,
    openModalForHtml,
    openParentModal,
    hasScrollBar,
    getScrollBarWidth,
    renderModalUrl
}

