import helper from "./helper";
import $ from 'jquery'

export const initError = (xhr) => {
    $('.m-loader').attr("disabled", false).removeClass('m-loader m-loader--light m-loader--left');
    try {
        let response, errorMessages;
        if (typeof xhr.response !== 'undefined') {
            if (typeof xhr.response.data !== 'undefined') {
                response = xhr.response.data;
            } else {
                response = JSON.parse(xhr.responseText);
            }

            errorMessages = response.error.message;
            if (typeof response.error.code !== 'undefined' &&
                (response.error.code === "TOKEN_EXPIRED" ||
                    response.error.code === "TOKEN_CHANGED" ||
                    response.error.code === "MISSING_TOKEN" ||
                    response.error.code === "INVALID_INDICATOR" ||
                    response.error.code === "MF_AUTH_REQUIRED" ||
                    response.error.code === "MF_SETUP_REQUIRED")
            ) {
                helper.loginRedirect();
                return false;
            }

            if (typeof response.error.detail !== 'undefined') {
                if (typeof response.error.detail.message !== 'undefined') {
                    errorMessages += '<br>' + response.error.detail.message;
                }
                if (typeof response.error.detail.previous !== 'undefined') {
                    errorMessages += '<br>' + response.error.detail.previous.message;
                }
            }
        } else {
            errorMessages = xhr;
        }

        if (window.$envConfig.APP_ENV === 'dev' && errorMessages.toString() === 'Error: Network Error') {
            helper.loginRedirect();
            return false;
        }

        helper.errorMessage(errorMessages);
        $('.loader-wrapper').hide();
    } catch (err) {
        helper.errorMessage("An error occurred.");
        $('.loader-wrapper').hide();
    }
};

export default {initError};