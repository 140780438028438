import $ from "jquery";

export const getWindowSize = () => {
    return {width: $(window).width(), height: $(window).height()};
};

export const getDocumentSize = () => {
    return {width: $(document).width(), height: $(document).height()};
};

export const getCssSize = () => {
    let win = getWindowSize();
    if (win.width <= 576) {
        return "xs";
    } else if (win.width > 576 && win.width <= 768) {
        return "sm";
    } else if (win.width > 768 && win.width <= 992) {
        return "md";
    } else if (win.width > 992 && win.width <= 1200) {
        return "lg";
    } else if (win.width > 1200) {
        return "xl";
    }
};

export const getOrientationImage = (elt, callBack) => {
    let img = new Image();
    img.src = elt.attr('src');
    img.onload = function () {
        let width = this.width;
        let height = this.height;
        let rtn = {};

        if (width > height) {
            rtn = {orientation: "landscape", width: width, height: height};
        } else {
            rtn = {orientation: "portrait", width: width, height: height};
        }
        callBack(rtn, elt);
    };
};

export default {
    getWindowSize,
    getDocumentSize,
    getCssSize,
    getOrientationImage
};

