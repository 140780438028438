import Vue from "vue";
import App from "srcVuePath/App.vue";
import env from "../env.json";

import router from "srcVuePath/router";
import store from "corePath/services/store";
import ApiService from "corePath/services/api.service";
import {VERIFY_AUTH} from "corePath/services/store/auth.module";
import {RESET_LAYOUT_CONFIG} from "corePath/services/store/config.module";
import VueSocialSharing from 'vue-social-sharing'

window.$envConfig = env;
Vue.prototype.$envConfig = env;
Vue.config.productionTip = false;
Vue.use(VueSocialSharing);
// Global 3rd party plugins
import "jquery";
import "popper.js";
import "bootstrap";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";

window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";

window.ClipboardJS = ClipboardJS;
window.allDatableObj = {};
window.scrollBarWith = 0;

// Vue 3rd party plugins
import i18n from "corePath/plugins/vue-i18n";
import vuetify from "corePath/plugins/vuetify";
import "corePath/plugins/portal-vue";
import "corePath/plugins/bootstrap-vue";
import "corePath/plugins/perfect-scrollbar";
import "corePath/plugins/highlight-js";
import "corePath/plugins/inline-svg";
import "corePath/plugins/apexcharts";
import "corePath/plugins/treeselect";
import "corePath/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "corePath/plugins/formvalidation";
import {coreJs} from "utilPath/coreJs";

// API service init
ApiService.init();
//coreJs start
coreJs.initStart();

router.beforeEach((to, from, next) => {
    Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG);

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

window.vm = new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount("#app");
