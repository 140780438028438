import $ from "jquery";

import i18n from "corePath/plugins/vue-i18n";
import select2Util from "utilPath/select2-util";
import {isMobile, objectLength, serviceHeaderBuilder} from "./helper";
import {responsiveTable} from "./responsive-table-util";
import {_elementUtil} from "./element-util";
/*

import "datatables.net-colreorder";
import "datatables.net-keytable";
import "datatables.net-responsive";
import "datatables.net-rowgroup";
import "datatables.net-rowreorder";
import "datatables.net-scroller";
import "datatables.net-select";
import "datatables.net-autofill";
*/

//import "datatables.net-buttons";


//import "datatables.net-fixedheader";

//import "datatables.net-buttons";
//import "datatables.net-fixedcolumns";
//import "datatables.net";


import "datatables.net/js/jquery.dataTables";
//import "datatables.net-bs4/js/dataTables.bootstrap4";

//import "datatables.net-bs4/js/dataTables.bootstrap4";
//import "datatables.net-rowreorder/js/dataTables.rowReorder";
//import "datatables.net-fixedcolumns/js/dataTables.fixedColumns";
//import "datatables.net-fixedheader/js/dataTables.fixedHeader";


//import "../../../../assets/js/components/dataTableInit";


/*import "datatables.net-bs4/js/dataTables.bootstrap4";
import "../../../../assets/js/components/dataTableInit";
import "datatables.net-autofill/js/dataTables.autoFill.min.js";
import "datatables.net-autofill-bs4/js/autoFill.bootstrap4.min.js";
import "jszip/dist/jszip.min.js";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
import "datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-colreorder/js/dataTables.colReorder.min.js";
import "datatables.net-fixedcolumns/js/dataTables.fixedColumns.min.js";
import "datatables.net-fixedheader/js/dataTables.fixedHeader.min.js";
import "datatables.net-keytable/js/dataTables.keyTable.min.js";
import "datatables.net-responsive/js/dataTables.responsive.min.js";
import "datatables.net-responsive-bs4/js/responsive.bootstrap4.min.js";
import "datatables.net-rowgroup/js/dataTables.rowGroup.min.js";
import "datatables.net-rowreorder/js/dataTables.rowReorder.min.js";
import "datatables.net-scroller/js/dataTables.scroller.min.js";
import "datatables.net-select/js/dataTables.select.min.js"*/

//$.fn.dataTable.ext.errMode = 'none';
export const getValueDataTables = (data, key, index) => {
    if (typeof key[index] === 'undefined') {
        return "";
    } else if (typeof data === 'undefined' || data === null) {
        return "";
    } else if (typeof data[key[index]] === 'undefined' || data[key[index]] === null) {
        return "";
    } else if (typeof data[key[index]] === "object") {
        return getValueDataTables(data[key[index]], key, index + 1);
    } else {
        if (typeof data[key[index]] === 'string' && data[key[index]] !== null) {
            return data[key[index]].replace(/<script>/g, '&#60;script&#62;').replace(/<\/script>/g, '&#60;&#47;script&#62;');
        }
        return data[key[index]];
    }
};
export const renderValueDataTables = (value, attrs) => {
    for (let attr in attrs) {
        if (attr === 'data-table-render-type') {
            if (attrs[attr] === 'date') {
                if (value !== '') {
                    if (value.length === 10) {
                        let spl = value.split("-");
                        let year = spl[0];
                        let month = spl[1];
                        let day = spl[2];
                        value = day + "/" + month + "/" + year;
                    } else if (value.length === 19) {
                        let spl = value.split(" ");
                        let date = spl[0];
                        let time = spl[1];
                        spl = date.split("-");
                        let year = spl[0];
                        let month = spl[1];
                        let day = spl[2];
                        value = day + "/" + month + "/" + year + " " + time;
                    }
                }
            }
            break;
        }
    }

    return value;
};
export const renderDataTables = (data, type, row, meta) => {
    let parameters = meta.settings.oInit.columnDefs[meta.col].parameters;
    let newHtml = parameters.html;
    let newAttrs = parameters.attrs;
    let tableId = parameters.tableId;

    parameters.replace.forEach(function (field) {
        newHtml = newHtml.replace(field.value, renderValueDataTables(getValueDataTables(row, field.key.split("."), 0), newAttrs));
    });
    let cell = $(tableId + ' tbody>tr:eq(' + meta.row + ') td:eq(' + meta.col + ')');
    for (let newAttr in newAttrs) {
        if (newAttr === "class") {
            cell.addClass(newAttrs[newAttr]);
        } else {
            cell.attr(newAttr, newAttrs[newAttr]);
        }
    }
    return newHtml;
};
export const initDataTables = (tableId) => {
    let element = {};
    element.tableId = tableId;
    element.tableAttrId = "#" + tableId.attr('id');
    element.parentId = "";
    element.defaultColumnId = 0;
    if (tableId.parents(".modal").length > 0) {
        element.parentId = "#" + tableId.parents(".modal").first().attr("id") + " ";
        element.tableAttrId = element.parentId + "#" + element.tableId.attr("id");
        element.tableId = $(element.tableAttrId);
    }

    element.getColumnsTitle = function () {
        let columnsTitle = [];
        element.tableId.find("thead>tr>th").each(function () {
            columnsTitle.push($(this).text());
        });
        return columnsTitle;
    };
    element.getColumns = function () {
        let columns = [];
        let setDefaultColumn = false;
        element.tableId.find("thead>tr>th").each(function (index) {
            if ($(this).attr("data-table-response-key") != null) {
                let column = {
                    data: $(this).attr("data-table-response-key"),
                    name: $(this).text(),
                    orderable: $(this).attr("data-sort") !== false
                };
                columns.push(column);

                if (!setDefaultColumn) {
                    element.defaultColumnId = index;
                    setDefaultColumn = true;
                }
            } else {
                let column = {
                    data: null,
                    name: "",
                    orderable: false
                };
                columns.push(column);
            }
        });

        return columns;
    };
    element.getColumnsDefault = function () {
        let regex = new RegExp(/##(.*?)##/gm);
        let columnsDefault = [];
        let columnsTitle = element.getColumnsTitle();

        let wrapper = element.tableAttrId + '_wrapper';
        let footerReference = element.tableAttrId;
        if ($(wrapper).length > 0) {
            footerReference = wrapper + ' .dataTables_scrollFoot table';
        }

        $(footerReference + " tfoot>tr#reference>td").each(function (index) {
            let html = $(this).html().replace("data-img-src", "src");
            let attrs = element.getAttributes($(this));
            let m;
            let replaceData = [];

            while ((m = regex.exec(html)) !== null) {
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }

                for (let i1 = 0; i1 < m.length / 2; i1++) {
                    replaceData.push({value: m[i1], key: m[i1 + 1]});
                }
            }

            attrs['data-rt-label'] = columnsTitle[index] + (columnsTitle[index] !== "" ? ":" : "");

            let defaultColumn = {
                targets: index,
                title: columnsTitle[index],
                parameters: {
                    rowId: element.tableId.find("tfoot>tr").attr("data-table-row-id"),
                    html: html,
                    replace: replaceData,
                    attrs: attrs,
                    tableId: element.tableAttrId,
                },
                render: renderDataTables,
            };

            columnsDefault.push(defaultColumn);
        });

        return columnsDefault;
    };
    element.getDisplayLength = function () {
        let length = element.tableId.attr('data-table-default-show') != null ? parseInt(element.tableId.attr('data-table-default-show')) : 25;
        if (isMobile()) {
            length = 10;
        }
        return length;
    };
    element.dataTables = function () {
        if (element.tableId.length === 0) {
            return false;
        }

        let dataTableContainer = $('<div class="dataTables-div"></div>');
        dataTableContainer.insertBefore(element.tableId);
        tableId.appendTo(dataTableContainer);
        element.tableId.addClass("responsive-table");

        let methodUrl = typeof element.tableId.attr('data-method-url') !== 'undefined' ? element.tableId.attr('data-method-url') : null;
        let columns = element.getColumns();
        let columnsDefault = element.getColumnsDefault();
        let rowId = element.tableId.find("tfoot>tr").attr("data-table-row-id");

        if (element.tableId.attr("data-search-form") != null) {
            element.searchButton();
            element.resetButton();
        }

        if (element.tableId.attr('data-table-process') == null) {
            element.tableId.attr('data-table-process', "");
        }

        let scrollY = false;
        let scrollX = true;
        let fixedHeader = false;
        let fixedColumns = {
            leftColumns: 0,
            rightColumns: 0
        };
        if (typeof element.tableId.attr('data-table-left-fixed') !== 'undefined') {
            scrollX = true;
            fixedColumns.leftColumns = parseInt(element.tableId.attr('data-table-left-fixed'));
        }
        if (typeof element.tableId.attr('data-table-right-fixed') !== 'undefined') {
            scrollX = true;
            fixedColumns.rightColumns = parseInt(element.tableId.attr('data-table-right-fixed'));
        }

        if (typeof element.tableId.attr('data-table-header-fixed') !== 'undefined') {
            fixedHeader = true;
            scrollY = element.tableId.attr('data-table-header-fixed');
        }

        let col = 9;
        let dom = '<"top row"<"col-12 col-md-3 col-lg-3 responsive-table-div-height"l>';
        if (typeof element.tableId.attr("data-table-paging-type") === 'undefined' || element.tableId.attr("data-table-paging-type") !== 'simple') {
            col = 6;
            dom += '<"col-12 col-md-3 col-lg-3 responsive-table-div-height"i>';
        }
        dom += '<"col-12 col-md-' + col + ' col-lg-' + col + ' responsive-table-div-height dt-page-numbers"p>>rt<"bottom row mt-20px"' +
            '<"col-12 col-md-3 col-lg-3 responsive-table-div-height"l>' +
            '<"col-12 col-md-9 col-lg-9 responsive-table-div-height dt-page-numbers"p>><"clear">';


        element.table = element.tableId.dataTable({
            pagingType: typeof element.tableId.attr("data-table-paging-type") != 'undefined' ? element.tableId.attr("data-table-paging-type") : 'simple_numbers',
            searching: false,
            responsive: false,
            searchDelay: 500,
            processing: true,
            autoWidth: false,
            language: i18n.getLocaleMessage(i18n.locale).dataTable,
            order: (tableId.attr('data-table-default-sort-field') != null ? [[element.tableId.attr('data-table-default-sort-field'), element.tableId.attr('data-table-default-sort-type')]] : [element.defaultColumnId, 'asc']),
            rowId: rowId,
            displayLength: element.getDisplayLength(),
            lengthChange: !(tableId.attr('data-table-display-length') != null && tableId.attr('data-table-display-length') === 'false'),
            serverSide: true,
            dom: dom,
            classes: {
                sPageButton: 'page-link'
            },
            ajax: {
                type: 'POST',
                dataType: 'JSON',
                url: methodUrl,
                data: function (d) {
                    d.search_type = "datatables";
                    d.search_process = element.tableId.attr('data-table-process');
                    d.responseType = "dataTable";
                    d.searchColumns = element.searchFields();
                    d.listRequest = element.tableId.attr("data-table-list-request") != null ? element.tableId.attr("data-table-list-request") : "";
                    return JSON.stringify(d);
                },
                /*success:function (json) {
                  console.log('success data:',json);
                    element.tableId.dataTable.ext.internal._fnLanguageCompat( json );
                    element.tableId.dataTable.ext.internal._fnCamelToHungarian( i18n.getLocaleMessage(i18n.locale).dataTable, json );
                    $.extend( true, i18n.getLocaleMessage(i18n.locale).dataTable, json );
                    console.log('element.tableId.dataTable.oSettings:',element.tableId.dataTable.models.oSettings);
                    element.tableId.dataTable.ext.internal._fnInitialise( element.tableId.dataTable.models.oSettings );
                },*/
                beforeSend: serviceHeaderBuilder,
            },
            deferLoading: methodUrl === null ? 0 : null,
            columns: columns,
            columnDefs: columnsDefault,
            scrollY: scrollY,
            scrollX: scrollX,
            scrollCollapse: false,
            fixedHeader: fixedHeader,
            fixedColumns: fixedColumns,
            fnDrawCallback: function (settings) {
                element.drawCallBack();
                $(element.tableAttrId).attr("data-table-process", "");
                $('.m-loader').attr("disabled", false).removeClass('m-loader m-loader--light m-loader--left m-loader--danger');
                element.setAttr();
                element.setPagingButtons();

                //element.table.fixedHeader.adjust();
                //element.table.columns.adjust();
            },
            initComplete: function () {
                responsiveTable(element.tableId);
            }
        });

        element.drawCallBack();

        //element.table.fixedHeader.adjust();
        element.tableId.on('headerResize', function () {
            'dataTables_scrollHeadInner'
            element.table.fixedHeader.adjust();
        });

        return element.table;

    };
    element.drawCallBack = function () {
        let pageId = $(element.tableAttrId + "_wrapper");
        if (element.tableId.attr('data-table-default-show') === "all") {
            pageId.find(".dataTables_info").addClass("hidden");
            pageId.find(".dataTables_length").addClass("hidden");
            pageId.find(".dt-page-numbers").addClass("hidden");
        } else {
            if (typeof element.tableId.attr('data-table-paging-type') !== 'undefined' && element.tableId.attr('data-table-paging-type') === "simple") {
                pageId.find(".dt-page-numbers").removeClass("hidden");
            } else {
                pageId.find(".dt-page-numbers").addClass("hidden");
                if (pageId.find(".pagination>li").length > 6) {
                    pageId.find(".dt-page-numbers").removeClass("hidden");
                } else if (pageId.find(".paginate_button").length > 6) {
                    pageId.find(".dt-page-numbers").removeClass("hidden");
                } else if (pageId.find(".page-link").length > 6) {
                    pageId.find(".dt-page-numbers").removeClass("hidden");
                }
            }
        }

        let pageNumberId = $(element.tableAttrId +'_length');
        pageNumberId.find('select').addClass('custom-select custom-select-sm form-control form-control-sm');
    };
    element.init = function () {
        return element.dataTables();
    };
    element.searchFields = function () {
        let searchColumns = [];
        $(element.parentId + element.tableId.attr("data-search-form")).find("[data-search-key]").each(function () {
            let value = $(this).val();
            if (typeof $(this).attr("data-type") !== 'undefined') {
                if ($(this).attr("data-type") === 'date' && $(this).val() !== '') {
                    let spl = $(this).val().split("/");
                    let day = spl[0];
                    let month = spl[1];
                    let year = spl[2];
                    value = year + "-" + month + "-" + day;
                } else if ($(this).attr("data-type") === 'number' && $(this).val() !== '') {
                    value = Number(value);
                }
            }
            let searchColumn = {
                field: $(this).attr("data-search-key"),
                value: value,
                type: $(this).attr("data-search-type")
            };
            searchColumns.push(searchColumn);
        });
        return searchColumns;
    };
    element.searchButton = function () {
        let button = $(element.parentId + element.tableId.attr("data-search-form")).find("[data-button-type=search]");
        if ($(button) != null) {
            $(button).on('click', function () {
                let bc = button.css("background-color");
                let ld = 'm-loader--light';
                if (bc === 'rgb(255, 255, 255)' || bc === 'white' || bc === '#FFF' || bc === '#FFFFFF' || bc === '#f8f9fa' || bc === 'rgb(248, 249, 250)') {
                    ld = 'm-loader--danger';
                }
                button.attr("disabled", true).addClass('m-loader ' + ld + ' m-loader--left');
                element.table._fnAjaxUpdate();
                //element.table.ajax.reload();
            });
        }
    };
    element.resetButton = function () {
        let button = $(element.parentId + element.tableId.attr("data-search-form")).find("[data-button-type=reset]");
        if ($(button) != null) {
            $(button).on('click', function () {
                $(element.parentId + tableId.attr("data-search-form")).find("[data-search-key]").each(function () {
                    if (select2Util.select2IsExists($(this))) {
                        let defaultValue = $(this).find('option[data-default=true]');
                        $(this).val(defaultValue.length > 0 ? defaultValue.val() : "");
                        select2Util.select2Remover($(this));
                        select2Util.select2Create($(this));
                    } else if (typeof $(this).attr('data-input-type') !== 'undefined') {
                        let target = $($(this).attr('data-input-target'));
                        target.val("");
                        target.trigger('destination.clear');
                        $(this).val("");
                    } else {
                        $(this).val("");
                    }

                });
                element.table._fnAjaxUpdate();
                //element.table.ajax.reload();
            });
        }
    };
    element.getAttributes = function ($node) {
        let attrs = {};
        $.each($node[0].attributes, function (index, attribute) {
            attrs[attribute.name] = attribute.value;
        });
        return attrs;
    };
    element.setClass = function () {
        let defaultColumns = element.getColumnsDefault();
        $.each(defaultColumns, function (index, column) {
            if (typeof column.parameters.attrs !== 'undefined' && objectLength(column.parameters.attrs) > 0) {
                if (typeof column.parameters.attrs.class !== 'undefined') {
                    element.tableId.find('tbody>tr').find('td:eq(' + column.targets + ')').addClass(column.parameters.attrs.class);
                }
            }
        });
    }
    element.setAttr = function () {
        let defaultColumns = element.getColumnsDefault();

        $.each(defaultColumns, function (index, column) {
            if (typeof column.parameters.attrs !== 'undefined' && objectLength(column.parameters.attrs) > 0) {
                let cell = element.tableId.find('tbody>tr').find('td:eq(' + column.targets + ')');
                for (let newAttr in column.parameters.attrs) {
                    if (newAttr === "class") {
                        cell.addClass(column.parameters.attrs[newAttr]);
                    } else {
                        cell.attr(newAttr, column.parameters.attrs[newAttr]);
                    }
                }
            }
        });

        element.tableId.find('tbody>tr>td.dataTables_empty').attr('colspan', defaultColumns.length);

        element.tableId.find('[data-popover=true][data-popover-content!=""]').each(function () {
            let elt = $(this);
            elt.popover({
                trigger: 'hover',
                title: elt.attr('data-popover-title'),
                content: elt.attr('data-popover-content'),
                template: '<div class="m-popover popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
                html: true,
                sanitize: false,
            });
        });
    }
    element.setPagingButtons = function () {
        $('.page-link[aria-controls]').prop('href', 'javascript:void(0);');
    }

    return element;
};
export const loadDataTables = (element) => {
    let dataTableJs = initDataTables(element);
    dataTableJs.init();
    return dataTableJs;
};
export const loadDataTablesHtml = (tableId) => {
    let element = {
        table: null,
        tableId: tableId,
        tableAttrId: "#" + tableId.attr('id'),
        parentId: "",
        defaultColumnId: 0
    };
    if (tableId.parents(".modal").length > 0) {
        element.parentId = "#" + tableId.parents(".modal").first().attr("id") + " ";
        element.tableAttrId = element.parentId + "#" + element.tableId.attr("id");
        element.tableId = $(element.tableAttrId);
    }

    let rowId = element.tableId.attr("data-table-row-id");
    let paging = !(typeof element.tableId.attr('data-table-paging') != 'undefined' && element.tableId.attr('data-table-paging') === 'false');
    let scrollY = false;
    let scrollX = false;
    let fixedHeader = false;
    let fixedColumns = {
        leftColumns: 0,
        rightColumns: 0
    };

    if (typeof element.tableId.attr('data-table-left-fixed') !== 'undefined') {
        scrollX = true;
        fixedColumns.leftColumns = parseInt(element.tableId.attr('data-table-left-fixed'));
    }
    if (typeof element.tableId.attr('data-table-right-fixed') !== 'undefined') {
        scrollX = true;
        fixedColumns.rightColumns = parseInt(element.tableId.attr('data-table-right-fixed'));
    }

    if (typeof element.tableId.attr('data-table-header-fixed') !== 'undefined') {
        fixedHeader = true;
        scrollY = element.tableId.attr('data-table-header-fixed');
    }

    element.getDisplayLength = function () {
        let length = element.tableId.attr('data-table-default-show') != null ? parseInt(element.tableId.attr('data-table-default-show')) : 25;
        if (isMobile()) {
            length = 10;
        }

        return length;
    };

    element.setPagingButtons = function () {
        $('.page-link[aria-controls]').prop('href', 'javascript:void(0);');
    }

    element.table = element.tableId.DataTable({
        searching: false,
        responsive: false,
        processing: true,
        serverSide: false,
        rowId: rowId,
        language: i18n.getLocaleMessage(i18n.locale).dataTable,
        paging: paging,
        order: (element.tableId.attr('data-table-default-sort-field') != null ? [[element.tableId.attr('data-table-default-sort-field'), element.tableId.attr('data-table-default-sort-type')]] : [element.defaultColumnId, 'asc']),
        displayLength: element.getDisplayLength(),
        lengthChange: !(tableId.attr('data-table-display-length') != null && tableId.attr('data-table-display-length') === 'false'),
        dom: '<"top row"<"col-12 col-md-3 col-lg-3 responsive-table-div-height"l><"col-12 col-md-3 col-lg-3 responsive-table-div-height"i><"col-12 col-md-6 col-lg-6 responsive-table-div-height dt-page-numbers"p>>rt<"bottom row mt-20px"<"col-12 col-md-3 col-lg-3 responsive-table-div-height"l><"col-12 col-md-9 col-lg-9 responsive-table-div-height dt-page-numbers"p>><"clear">',
        scrollY: scrollY,
        scrollX: scrollX,
        fixedHeader: fixedHeader,
        fixedColumns: fixedColumns,
        fnDrawCallback: function () {
            element.setPagingButtons();
        },
        initComplete: function () {
            responsiveTable(element.tableId);
        }
    });
    return element;
}
export const changeSearchKey = (elt) => {
    let changeSearch = {
        element: elt,
        elementType: _elementUtil.getType(elt),
        defaultKey: typeof elt.attr('data-search-key') !== 'undefined' ? elt.attr('data-search-key') : elt.attr('data-method-key')
    };

    changeSearch.prop = {
        change: function () {
            let selectedOption = changeSearch.element.find('option:selected');
            let dataSearchKey = changeSearch.defaultKey;
            if (typeof selectedOption.attr('data-search-new-key') !== 'undefined') {
                dataSearchKey = selectedOption.attr('data-search-new-key');
            }
            if (typeof elt.attr('data-search-key') !== 'undefined') {
                changeSearch.element.attr('data-search-key', dataSearchKey);
            }
            if (typeof elt.attr('data-method-key') !== 'undefined') {
                changeSearch.element.attr('data-method-key', dataSearchKey);
            }
        }
    };
    if (changeSearch.elementType === 'select2') {
        changeSearch.element.on('change', function () {
            changeSearch.prop.change();
        });
    }
}

export default {
    loadDataTables,
    loadDataTablesHtml,
    changeSearchKey
}
