import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("viewVuePath/layout/Layout"),
            children: [
                {
                    path: "/" +
                        "dashboard",
                    name: "dashboard",
                    component: () => import("viewVuePath/pages/dashboard.vue")
                },
                {
                    path: "/reviews",
                    name: "Reviews",
                    component: () => import("viewVuePath/pages/reviews.vue")
                },
                {
                    path: "/reviews/sentiment-analysis",
                    name: "Sentiment Analysis",
                    component: () => import("viewVuePath/pages/sentiment-analysis.vue")
                },
                {
                    path: "/reviews/statistics",
                    name: "Statistics",
                    component: () => import("viewVuePath/pages/statistics.vue")
                },
                {
                    path:"/reviews/statistics/text-generation",
                    name:"Text Generation",
                    component: ()=>import("viewVuePath/pages/text-generation.vue")
                }, {
                    path:"/reviews/statistics/text-generation-istatistics",
                    name:"Text Generation",
                    component: ()=>import("viewVuePath/pages/text-generation-statistics.vue")
                },
                {
                    path: "/settings",
                    name: "Settings",
                    component: () => import("viewVuePath/pages/settings.vue")
                },
                {
                    path: "/reviews/statistics/hotel",
                    name: "Hotel Statistics",
                    props: {name: "Hotel Statistics", category: 'hotelScore'},
                    component: () => import("viewVuePath/pages/statisticsCategory/hotel-score.vue")
                },
                {
                    path: "/reviews/statistics/rooms",
                    name: "Rooms Statistics",
                    props: {name: "Rooms Statistics", category: 'roomsScore'},
                    component: () => import("viewVuePath/pages/statisticsCategory/room-score.vue")
                },
                {
                    path: "/reviews/statistics/meal",
                    name: "Meal Statistics",
                    props: {name: "Meal Statistics", category: 'mealScore'},
                    component: () => import("viewVuePath/pages/statisticsCategory/meal-score.vue")
                },
                {
                    path: "/reviews/statistics/staff",
                    name: "Staff Statistics",
                    props: {name: "Staff Statistics", category: 'staffScore'},
                    component: () => import("viewVuePath/pages/statisticsCategory/staff-score.vue")
                },
                {
                    path: "/reviews/statistics/transportation",
                    name: "Transportation Statistics",
                    props: {name: "Transportation Statistics", category: 'transportationScore'},
                    component: () => import("viewVuePath/pages/statisticsCategory/transportation-score.vue")
                },
                {
                    path: "/reviews/statistics/service",
                    name: "Services Statistics",
                    props: {category: 'serviceScore', name: "Services Statistics"},
                    component: () => import("viewVuePath/pages/statisticsCategory/services-score.vue")
                },
                {
                    path: "/reviews/statistics/location",
                    name: "Location Statistics",
                    props: {category: 'locationScore', name: "Location Statistics"},
                    component: () => import("viewVuePath/pages/statisticsCategory/location-score.vue")
                },
                {
                    path: "/reviews/statistics/activities",
                    name: "Activities Statistics",
                    props: {category: 'activitiesScore', name: "Activities Statistics"},
                    component: () => import("viewVuePath/pages/statisticsCategory/activities-score.vue")
                },
                {
                    path: "/reviews/statistics/cleanness",
                    name: "Cleanness Statistics",
                    props: {category: 'cleannessScore', name: "Cleanness Statistics"},
                    component: () => import("viewVuePath/pages/statisticsCategory/cleanness-score.vue")
                },
            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("viewVuePath/pages/error/error-404.vue")
        }
    ]
});
