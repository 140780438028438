import {getQueryParameterByName} from "utilPath/helper";

const ID_TOKEN_KEY = "_accessToken";
const ID_INDICATOR_KEY = "indicator";
const USER_ID_KEY = "userId";

export const checkIndicator = () => {
    let indicator = getQueryParameterByName(ID_INDICATOR_KEY);
    return !(indicator === null || indicator === "");
};

export const getIndicator = () => {
    return getQueryParameterByName(ID_INDICATOR_KEY);
};

export const getUserId = () => {
    return getQueryParameterByName(USER_ID_KEY);
};

export const getToken = () => {
    return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
    window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default {checkIndicator, getIndicator, getUserId, getToken, saveToken, destroyToken};
