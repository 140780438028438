import $ from "jquery";
import {select2IsExists} from "./select2-util";
import {initError} from "./handleError";
import {numberFormatRemoveUtil, numberFormatUtil} from "./number-format-util";
import {removeValidation} from "./removeValidation";
import {alertMessage, serviceHeaderBuilder, warningMessage} from "./helper";


/** case use
 'data-prop-click-case' : '',
 'data-prop-click-true-disable' : '#perRoom,#perDate,#perAdult,#perChildB,#perChildA,#perInfant',
 'data-prop-click-true-uncheck' : '#perRoom,#perDate,#perAdult,#perChildB,#perChildA,#perInfant',
 'data-prop-click-false-enable' : '#perRoom,#perDate,#perAdult,#perChildB,#perChildA,#perInfant',
 'data-prop-click-false-check' : '#perRoom,#perDate,#perAdult,#perChildB,#perChildA,#perInfant',
 */

/** default use
 'data-prop-click-disable' : '#perRoom,#perDate,#perAdult,#perChildB,#perChildA,#perInfant',
 'data-prop-click-uncheck' : '#perRoom,#perDate,#perAdult,#perChildB,#perChildA,#perInfant',
 */
export const elementUtil = (options, parent) => {
    let element = {
        element: typeof options !== 'undefined' ? $(options.element) : null,
        parent: typeof parent !== 'undefined' ? parent : null,
        attributes: typeof options !== 'undefined' ? options.element.attributes : null,
        params: {},
        getType: function (element) {
            let which = typeof element !== 'undefined' ? element : element.element;
            if (which.prop("nodeName") === "INPUT" && which.prop("type") === "checkbox") {
                return "checkbox";
            } else if (which.prop("nodeName") === "INPUT" && which.prop("type") === "radio") {
                return "radio";
            } else if (which.prop("nodeName") === "INPUT" && which.prop("type") === "button") {
                return "button";
            } else if (which.prop("nodeName") === "BUTTON" && which.prop("type") === "button") {
                return "button";
            } else if (which.prop("nodeName") === "A") {
                return "link";
            } else if (select2IsExists(which)) {
                return "select2";
            } else {
                return typeof which !== 'undefined' ? which.prop("nodeName").toLowerCase() : null;
            }
        },
        getCase: function (state) {
            let events = [];
            $.each(element.attributes, function (i, a) {
                let splt = a.name.split("-");
                let prop = {};
                if (splt[1] === 'prop' && state === splt[3]) {

                    let event = splt[3].toString();
                    let method = checkElementUtil(a.name, "case");
                    if (method != null) {
                        prop[method] = a.value.split(",");
                        if (typeof events[event] === 'undefined') {
                            events[event] = {'name': event, 'prop': []};
                        }
                        events[event].prop.push(prop);
                    }
                }
            });
            return events;
        },
    };

    element.ajax = {
        method: function (callMethod) {
            if (element.prop.getParam("method-type") != null) {
                return element.prop.getParam("method-type");
            }

            if (typeof element.params.methodType !== 'undefined') {
                return element.params.methodType;
            }

            switch (callMethod) {
                case 'edit':
                    return 'PUT';
                case 'delete':
                    return 'DELETE';
                case 'get':
                    return 'GET';
                default:
                    return 'POST';
            }

        },
        dataValue: function (elt) {
            let elementType = element.getType(elt);
            if (elementType === 'radio' || elementType === 'checkbox') {
                if (typeof elt.attr("value") === 'undefined') {
                    if (elt.is(':checked')) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }

            return elt.val().trim() === "" && element.prop.getParam("is-null", elt) != null && element.prop.getParam("is-null", elt) === 'true' ? null : elt.val();
        },
        dataType: function () {
            if (typeof element.params.dataType !== 'undefined') {
                return element.params.dataType;
            }
            return element.prop.getParam("data-type") != null ? element.prop.getParam("data-type") : 'JSON';
        },
        getValue: function (data) {
            let regex = /\\#(.*?)\\#/gm;
            let replaceData = [];
            let m;
            while ((m = regex.exec(data)) !== null) {
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }
                for (let i1 = 0; i1 < m.length / 2; i1++) {
                    replaceData.push({value: m[i1], key: m[i1 + 1]});
                }
            }

            if (replaceData.length > 0) {
                for (let i = 0; i < replaceData.length; i++) {
                    let rpc = "\\#" + replaceData[i].key + "\\#";
                    let val = element.ajax.dataValue($("#" + replaceData[i].key));
                    data = data.replace(rpc, val);
                }
            }
            return data;
        },
        data: function () {
            let data = '';
            if (element.prop.getParam("data") != null) {
                data = element.ajax.getValue(element.prop.getParam("data"));
            }

            if (typeof element.params.data !== 'undefined') {
                data = element.params.data;
            }

            return data;
        },
        url: function (callMethod) {
            return element.prop.getParam("url") != null ? element.prop.getParam("url") : callMethod;
        },
        addLoader: function (elt) {
            if (typeof elt === 'undefined') {
                elt = element.element;
            }
            if (elt !== null) {
                if (select2IsExists(elt)) {
                    elt.next(".select2-container").addClass("m-loader m-loader--primary m-loader--right");
                } else if (element.getType(elt) === 'checkbox' || element.getType(elt) === 'radio') {
                    elt.next("span").addClass("m-loader m-loader--primary m-loader-chk");
                } else if (element.getType(elt) === 'input') {
                    elt.addClass("loading-right");
                } else if (element.getType(elt) === 'button') {
                    elt.addClass("m-loader m-loader--light m-loader--left");
                } else if (typeof elt.attr('data-loader-class') !== 'undefined' && elt.attr('data-loader-class') !== null && elt.attr('data-loader-class') !== '') {
                    elt.addClass(elt.attr('data-loader-class'));
                } else {
                    elt.after($('<span />', {class: "m-loader m-loader--primary m-loader--right"}));
                }
            }
        },
        removeLoader: function (elt) {
            if (typeof elt === 'undefined') {
                elt = element.element;
            }
            if (elt !== null) {
                if (select2IsExists(elt)) {
                    elt.next(".select2-container").removeClass("m-loader m-loader--primary m-loader--right");
                } else if (element.getType(elt) === 'checkbox' || element.getType(elt) === 'radio') {
                    elt.next("span").removeClass("m-loader m-loader--primary m-loader-chk");
                } else if (element.getType(elt) === 'input') {
                    elt.removeClass("loading-right");
                } else if (element.getType(elt) === 'button') {
                    elt.removeClass("m-loader m-loader--light m-loader--left");
                } else if (typeof elt.attr('data-loader-class') !== 'undefined' && elt.attr('data-loader-class') !== null && elt.attr('data-loader-class') !== '') {
                    elt.removeClass(elt.attr('data-loader-class'));
                } else {
                    elt.next("span.m-loader").remove();
                }
            }
        },
        renderMessage: function () {
            let type = element.getType(element.element);
            let elt = null;
            if (type === "select2") {
                elt = element.element.find(":selected");
            } else {
                elt = element.element;
            }
            return element.prop.getParam("message").replace("#text#", elt.text()).replace("#value#", elt.val());
        },
        send: function (callMethod, callUrl) {
            let isContinue = true;
            if (element.prop.getParam("call-before") !== null) {
                if (typeof window[element.prop.getParam("call-before")] !== 'undefined') {
                    isContinue = window[element.prop.getParam("call-before")](element.element);
                }
            }
            if (element.prop.getParam("is-empty") !== null) {
                if (element.prop.getParam("is-empty") === 'false' && element.element.val() === "") {
                    isContinue = false;
                }
            }
            if (typeof element.params.callBefore !== 'undefined') {
                isContinue = element.params.callBefore(element.element);
            }
            if (isContinue) {
                element.ajax.addLoader(element.element);
                $.ajax({
                    type: element.ajax.method(callMethod),
                    dataType: element.ajax.dataType(),
                    url: element.ajax.url(callUrl),
                    data: element.ajax.data(),
                    beforeSend: serviceHeaderBuilder,
                    success: function (response, textStatus, xhr) {
                        element.ajax.removeLoader(element.element);
                        if (element.prop.getParam("message") !== null) {
                            alertMessage(element.ajax.renderMessage());
                        }
                        if (element.prop.getParam("call-back") !== null) {
                            if (typeof window[element.prop.getParam("call-back")] !== 'undefined') {
                                window[element.prop.getParam("call-back")](element.element, response, status, xhr);
                            }
                        }

                        if (typeof element.params.message !== 'undefined') {
                            alertMessage(element.params.message);
                        }

                        let timmer = 0;
                        if (typeof response.data !== 'undefined' && typeof response.data.successMessages !== 'undefined') {
                            $.each(response.data.successMessages, function (index, message) {
                                setTimeout(function () {
                                    alertMessage(message);
                                }, timmer);
                                timmer += 150;
                            });
                        }
                        if (typeof response.data !== 'undefined' && typeof response.data.failMessages !== 'undefined') {
                            $.each(response.data.failMessages, function (index, message) {
                                setTimeout(function () {
                                    warningMessage(message);
                                }, timmer);
                                timmer += 150;
                            });
                        }

                        if (typeof element.params.callBack !== 'undefined') {
                            element.params.callBack(element.element, response, status, xhr);
                        }
                    },
                    error: function (xhr) {
                        if (typeof element.params.callBackError !== 'undefined') {
                            element.ajax.removeLoader(element.element);
                            element.params.callBackError(element.element, xhr);
                        } else {
                            initError(xhr);
                        }

                    }
                });
            } else {
                if (element.prop.getParam("call-back") !== null) {
                    if (typeof window[element.prop.getParam("call-back")] !== 'undefined') {
                        window[element.prop.getParam("call-back")](element.element, null, null, null);
                    }
                }
            }
        },
    };

    element.prop = {
        getSubElement: function (sub) {
            if ($.type(sub) === 'object') {
                return sub;
            }
            let searchKey = sub;

            if (sub.charAt(0) === '*') {
                searchKey = "[data-prop-filter-key*=" + sub.replace("*", "").replace(/\./gi, '\\.').replace("++", "") + "]";
            }
            if (element.parent != null) {
                if (searchKey.indexOf(element.parent) > -1) {
                    return $(searchKey);
                } else {
                    return $(element.parent + " " + searchKey);
                }

            } else {
                return $(searchKey);
            }
        },
        getSubElementForLabel: function (sub) {

            if ($.type(sub) === 'object') {
                sub = "#" + sub.attr('id');
            }

            if (element.parent != null) {
                try {
                    return sub.charAt(0) === '#' && $(element.parent + " [for=" + sub.replace("#", "").replace("*", "") + "]").length > 0 ? $(element.parent + " [for=" + sub.replace("#", "").replace("*", "") + "]") : $("[for=undefined]");
                } catch (err) {
                    return $("[for=undefined]");
                }
            } else {
                try {
                    return sub.charAt(0) === '#' && $("[for=" + sub.replace("#", "").replace("*", "") + "]").length > 0 ? $("[for=" + sub.replace("#", "").replace("*", "") + "]") : $("[for=undefined]");
                } catch (err) {
                    return $("[for=undefined]");
                }
            }
        },
        getRelationElement: function (reSub) {
            let subs = [];
            if (typeof reSub.attr("data-relation-element") !== 'undefined') {
                subs = reSub.attr("data-relation-element").split(",");
            }
            return subs;
        },
        getParam: function (param, elt) {
            let _elt = typeof elt === 'undefined' ? element.element : elt;
            let caseWhich = '';
            if (element.element !== null && typeof element.element.attr("data-prop-params-case") !== 'undefined') {
                if (typeof _elt.attr("data-prop-params-" + element.element.attr("data-prop-params-case") + '-' + param) !== 'undefined') {
                    caseWhich = element.element.attr("data-prop-params-case") + "-";
                }
            }
            return _elt != null && typeof _elt.attr("data-prop-params-" + caseWhich + param) !== 'undefined' ? _elt.attr("data-prop-params-" + caseWhich + param) : null;
        },
        setParam: function (param, elt, value) {
            let _elt = typeof elt === 'undefined' ? element.element : elt;
            if (typeof _elt.attr("data-prop-params-" + param) !== 'undefined') {
                _elt.attr("data-prop-params-" + param, value);
            }
        },
        getElements: function (elements) {
            if (typeof elements === 'string') {
                return [elements];
            }

            if ($.type(elements) === 'object') {
                return [elements];
            }
            return elements;
        },
        setParams: function (params) {
            if (typeof params !== 'undefined' && params !== null) {
                element.params = params;
                if (typeof element.params.element !== 'undefined') {
                    element.element = element.params.element;
                }
            }
        },
        method: {
            uncheck: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).prop('checked', false);
                });
            },
            check: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).prop('checked', true);
                });
            },
            disable: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    if (element.getType(element.prop.getSubElement(sub)) === 'checkbox' || element.getType(element.prop.getSubElement(sub)) === 'radio') {
                        element.prop.getSubElement(sub).prop('disabled', true);
                        element.prop.getSubElement(sub).parent().addClass("disabled");
                        element.prop.getSubElement(sub).addClass("none");
                    } else {
                        element.prop.getSubElement(sub).attr('disabled', 'disabled');
                    }
                    removeValidation(element.prop.getSubElement(sub));
                    let relationElements = element.prop.getRelationElement(element.prop.getSubElement(sub));
                    if (relationElements.length > 0) {
                        element.prop.method.disable(relationElements);
                    }
                });
            },
            enable: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    if (element.getType(element.prop.getSubElement(sub)) === 'checkbox' || element.getType(element.prop.getSubElement(sub)) === 'radio') {
                        element.prop.getSubElement(sub).prop('disabled', false);
                        element.prop.getSubElement(sub).parent().removeClass("disabled");
                        element.prop.getSubElement(sub).addClass("none");
                    } else {
                        element.prop.getSubElement(sub).removeAttr('disabled');
                    }
                    let relationElements = element.prop.getRelationElement(element.prop.getSubElement(sub));
                    if (relationElements.length > 0) {
                        element.prop.method.enable(relationElements);
                    }
                });
            },
            show: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).show("fast", function () {
                    });
                    element.prop.getSubElementForLabel(sub).show("fast", function () {
                    });
                });
            },
            hide: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).hide("fast", function () {
                    });
                    element.prop.getSubElementForLabel(sub).hide("fast", function () {
                    });
                });
            },
            showSlow: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).show("slow", function () {
                    });
                    element.prop.getSubElementForLabel(sub).show("slow", function () {
                    });
                });
            },
            hideSlow: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).hide("slow", function () {
                    });
                    element.prop.getSubElementForLabel(sub).hide("slow", function () {
                    });
                });
            },
            slideShow: function (elements, callBack) {
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).slideDown("fast", function () {
                        if (typeof callBack !== 'undefined') {
                            callBack();
                        }
                    });
                    element.prop.getSubElementForLabel(sub).slideDown("fast", function () {
                    });
                });
            },
            slideHide: function (elements, callBack) {
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).slideUp("fast", function () {
                        if (typeof callBack !== 'undefined') {
                            callBack();
                        }
                    });
                    element.prop.getSubElementForLabel(sub).slideUp("fast", function () {
                    });
                });
            },
            slideShowSlow: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).slideDown("slow", function () {
                    });
                    element.prop.getSubElementForLabel(sub).slideDown("slow", function () {
                    });
                });
            },
            slideHideSlow: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).slideUp("slow", function () {
                    });
                    element.prop.getSubElementForLabel(sub).slideUp("slow", function () {
                    });
                });
            },
            case: function () {
                if (element.getType(element.element) === "checkbox" || element.getType(element.element) === "radio") {
                    if (element.element.is(':checked')) {
                        let events = element.getCase("true");
                        for (let event in events) {
                            events[event].prop.forEach(function (prop) {
                                for (let subProp in prop) {
                                    element.prop.callMethod(subProp, prop[subProp]);
                                }
                            });
                        }

                        element.element.attr("data-propped-true", "true");
                    } else {
                        let events = element.getCase("false");
                        for (let event in events) {
                            events[event].prop.forEach(function (prop) {
                                for (let subProp in prop) {
                                    element.prop.callMethod(subProp, prop[subProp]);
                                }
                            });
                        }
                        element.element.attr("data-propped-false", "true");
                    }
                } else if (element.getType(element.element) === 'button' || element.getType(element.element) === 'link') {
                    let paramCase = element.prop.getParam("case", element.element);
                    let events = element.getCase(paramCase);
                    for (let event in events) {
                        events[event].prop.forEach(function (prop) {
                            for (let subProp in prop) {
                                element.prop.callMethod(subProp, prop[subProp]);
                            }
                        });
                    }

                    element.prop.setParam("case", element.element, paramCase === 'true' ? 'false' : 'true');
                    element.element.attr("data-propped-" + paramCase, paramCase);
                } else if (element.getType(element.element) === 'select' || element.getType(element.element) === 'select2') {
                    let paramCase;
                    if (element.prop.getParam("selected") !== null) {
                        paramCase = element.element.val() === element.prop.getParam("selected") ? 'true' : 'false';
                    } else {
                        paramCase = element.element.val() === null || element.element.val() === '' ? 'false' : 'true';
                    }

                    let events = element.getCase(paramCase);
                    for (let event in events) {
                        events[event].prop.forEach(function (prop) {
                            for (let subProp in prop) {
                                element.prop.callMethod(subProp, prop[subProp]);
                            }
                        });
                    }

                    element.prop.setParam("case", element.element, paramCase === 'true' ? 'false' : 'true');
                    element.element.attr("data-propped-" + paramCase, paramCase);
                } else if (element.getType(element.element) === 'input') {
                    let paramCase = element.element.val() === null || element.element.val() === '' ? 'false' : 'true';
                    let events = element.getCase(paramCase);
                    for (let event in events) {
                        events[event].prop.forEach(function (prop) {
                            for (let subProp in prop) {
                                element.prop.callMethod(subProp, prop[subProp]);
                            }
                        });
                    }

                    element.prop.setParam("case", element.element, paramCase === 'true' ? 'false' : 'true');
                    element.element.attr("data-propped-" + paramCase, paramCase);
                }
            },
            addValidation: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).attr("required", "true");
                    element.prop.getSubElement(sub).attr("aria-required", "true");
                    element.prop.getSubElementForLabel(sub).find("span[class*=required-dot]").each(function () {
                        $(this).removeClass("hidden");
                    });
                    if (typeof element.prop.getSubElement(sub).attr("data-msg-required") === 'undefined') {
                        element.prop.getSubElement(sub).attr("data-msg-required", "Please control this field.");
                    }
                });
            },
            removeValidation: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).removeAttr("required");
                    element.prop.getSubElement(sub).removeAttr("aria-required");
                    element.prop.getSubElement(sub).removeAttr("aria-invalid");
                    $(element.prop.getSubElement(sub).attr("id") + "-error").remove();
                    element.prop.getSubElement(sub).parent().removeClass("has-danger");
                    element.prop.getSubElementForLabel(sub).find("span[class*=required-dot]").each(function () {
                        $(this).addClass("hidden");
                    });
                    removeValidation(element.prop.getSubElement(sub));
                });
            },
            addMethodKey: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    if (typeof element.prop.getSubElement(sub).attr("data-method-key-removed") !== 'undefined') {
                        element.prop.getSubElement(sub).attr("data-method-key", element.prop.getSubElement(sub).attr("data-method-key-removed"));
                        element.prop.getSubElement(sub).removeAttr("data-method-key-removed");
                    }
                });
            },
            removeMethodKey: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    if (typeof element.prop.getSubElement(sub).attr("data-method-key") !== 'undefined') {
                        element.prop.getSubElement(sub).attr("data-method-key-removed", element.prop.getSubElement(sub).attr("data-method-key"));
                        element.prop.getSubElement(sub).removeAttr("data-method-key");
                    }
                });
            },
            filter: function () {

            },
            emptyValue: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).val("");
                });
            },
            getService: function (url, params) {
                element.prop.setParams(params);
                element.ajax.send('get', url);
            },
            editService: function (url, params) {
                element.prop.setParams(params);
                element.ajax.send('edit', url);
            },
            postService: function (url, params) {
                element.prop.setParams(params);
                element.ajax.send('post', url);
            },
            addClass: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    if (element.prop.getParam("add-class") !== null) {
                        element.prop.getSubElement(sub).addClass(element.prop.getParam("add-class", element.element));
                    } else {
                        element.prop.getSubElement(sub).addClass(element.prop.getParam("class", element.element));
                    }
                });
            },
            removeClass: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    if (element.prop.getParam("remove-class") !== null) {
                        element.prop.getSubElement(sub).removeClass(element.prop.getParam("remove-class", element.element));
                    } else {
                        element.prop.getSubElement(sub).removeClass(element.prop.getParam("class", element.element));
                    }
                });
            },
            setValue: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    let spl = sub.split(":");
                    let elt = spl[0];
                    let val = spl[1];
                    if (spl[1].charAt(0) === '[' && spl[1].charAt(spl[1].length - 1) === ']') {
                        let attr = spl[1].substr(1, spl[1].length - 2);
                        if (element.getType(element.element) === 'SELECT' || element.getType(element.element) === 'select2') {
                            val = element.element.find(':selected').attr(attr);
                        } else {
                            val = element.element.attr(spl[1]);
                        }
                    }
                    if (typeof element.prop.getSubElement(elt).attr('data-method-sub-key') !== 'undefined') {
                        element.prop.getSubElement(elt).attr('data-method-sub-key', val);
                    } else {
                        element.prop.getSubElement(elt).val(val);
                    }
                });
            },
            setAttr: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    let spl = sub.split(":");
                    let elt = spl[0];
                    let val = spl[1];
                    if (spl[1].charAt(0) === '[' && spl[1].charAt(spl[1].length - 1) === ']') {
                        let attr = spl[1].substr(1, spl[1].length - 2);
                        if (element.getType(element.element) === 'SELECT' || element.getType(element.element) === 'select2') {
                            val = element.element.find(':selected').attr(attr);
                        } else {
                            val = element.element.attr(spl[1]);
                        }

                        element.prop.getSubElement(elt).attr(attr, val);
                    }

                });
            },
            toggle: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).toggle("slide", function () {
                    });
                });
            },
            changeText: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).text(element.prop.getParam("text", element.element));
                });
            },
            changeHtml: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).html(element.prop.getParam("html", element.element));
                });
            },
            getType: function (elements) {
                return element.getType(elements);
            },
            addValidationGroup: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).attr("required", "true");
                    element.prop.getSubElement(sub).attr("aria-required", "true");
                    element.prop.getSubElementForLabel(sub).find("span[class*=required-dot]").each(function () {
                        $(this).removeClass("hidden");
                    });

                    if (element.prop.getParam("msg") !== null) {
                        element.prop.getSubElement(sub).attr("data-msg-required", element.prop.getParam("msg"));
                    } else if (typeof element.prop.getSubElement(sub).attr("data-msg-required") === 'undefined') {
                        element.prop.getSubElement(sub).attr("data-msg-required", "Please control this field.");
                    }

                });
            },
            removeValidationGroup: function (elements) {
                let isRemove = true;
                element.prop.getElements(elements).forEach(function (sub) {
                    element.prop.getSubElement(sub).each(function () {
                        if ($(this).val() !== null && $(this).val().trim() !== "") {
                            isRemove = false;
                        }
                    });

                });

                if (isRemove) {
                    element.prop.getElements(elements).forEach(function (sub) {
                        element.prop.getSubElement(sub).removeAttr("required");
                        element.prop.getSubElement(sub).removeAttr("aria-required");
                        element.prop.getSubElement(sub).removeAttr("aria-invalid");
                        $(element.prop.getSubElement(sub).attr("id") + "-error").remove();
                        element.prop.getSubElement(sub).parent().removeClass("has-danger");
                        element.prop.getSubElementForLabel(sub).find("span[class*=required-dot]").each(function () {
                            $(this).addClass("hidden");
                        });
                        element.prop.getSubElement(sub).removeAttr("data-msg-required");
                        removeValidation(element.prop.getSubElement(sub));
                    });
                }

            },
            showAll: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    let height = element.prop.getParam("show");
                    if (height === 'full') {
                        height = element.prop.getSubElement(sub).find(":first").height();
                        element.prop.getSubElement(sub).css("overflow-y", "auto");
                    }
                    element.prop.getSubElement(sub).animate({height: height}, 500);
                });
            },
            hideAll: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    let height = element.prop.getParam("hide");
                    element.prop.getSubElement(sub).css("overflow-y", "auto");
                    $([document.documentElement, document.body]).animate({
                        scrollTop: element.prop.getSubElement(sub).offset().top
                    }, 500);
                    element.prop.getSubElement(sub).animate({height: height}, 500);
                });
            },
            addLoader: function (elements) {
                element.element = elements;
                return element.ajax.addLoader();
            },
            removeLoader: function (elements) {
                element.element = elements;
                return element.ajax.removeLoader();
            },
            dataTypeNumber: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    numberFormatRemoveUtil(element.prop.getSubElement(sub));
                    element.prop.getSubElement(sub).attr('data-type', 'number');
                    element.prop.getSubElement(sub).val('');
                    numberFormatUtil(element.prop.getSubElement(sub));
                });
            },
            dataTypeDecimal: function (elements) {
                element.prop.getElements(elements).forEach(function (sub) {
                    numberFormatRemoveUtil(element.prop.getSubElement(sub));
                    element.prop.getSubElement(sub).attr('data-type', 'decimal');
                    element.prop.getSubElement(sub).val('');
                    numberFormatUtil(element.prop.getSubElement(sub));
                });
            }
        },

        callMethod: function (methodName, methodElements) {
            let splt = methodName.split("-");
            let method = splt[0];

            if (splt.length > 1) {
                for (let i = 1; i < splt.length; i++) {
                    method += splt[i].charAt(0).toUpperCase() + splt[i].slice(1);
                }
            }

            for (let process in element.prop.method) {
                if (process === method) {
                    return element.prop.method[process](methodElements);
                }
            }
            return null;
        },
        init: function () {
            element.element.attr("data-propped", "true");
            for (let event in options.event) {
                element.element.on(event, function () {
                    options.event[event].prop.forEach(function (prop) {
                        for (let subProp in prop) {
                            element.prop.callMethod(subProp, prop[subProp]);
                        }
                    });
                });
            }
        }
    };

    if (element.element != null) {
        element.prop.init();
    } else {
        return element.prop.method;
    }
};

export const checkElementUtil = (name, where) => {
    let splt = name.split("-");
    let method = "";

    if (splt.length === 4) {
        method = splt[3];
    } else if (splt.length === 5) {
        if (typeof where !== 'undefined' && where === 'case') {
            method = splt[4];
        } else {
            method = splt[3] + "-" + splt[4];
        }
    } else if (splt.length === 6) {
        if (typeof where !== 'undefined' && where === 'case') {
            method = splt[4] + "-" + splt[5];
        } else {
            method = splt[3] + "-" + splt[4] + "-" + splt[5];
        }
    } else if (splt.length === 7) {
        if (typeof where !== 'undefined' && where === 'case') {
            method = splt[4] + "-" + splt[5] + "-" + splt[6];
        }
    }

    let isExistsMethod = function (methodName) {
        switch (methodName) {
            case 'check':
                return true;
            case 'uncheck':
                return true;
            case 'disable':
                return true;
            case 'enable':
                return true;
            case 'show':
                return true;
            case 'hide':
                return true;
            case 'show-slow':
                return true;
            case 'hide-slow':
                return true;
            case 'slide-show':
                return true;
            case 'slide-hide':
                return true;
            case 'slide-show-slow':
                return true;
            case 'slide-hide-slow':
                return true;
            case 'case':
                return true;
            case 'add-validation':
                return true;
            case 'remove-validation':
                return true;
            case 'add-method-key':
                return true;
            case 'remove-method-key':
                return true;
            case 'filter':
                return true;
            case 'empty-value':
                return true;
            case 'get-service':
                return true;
            case 'edit-service':
                return true;
            case 'add-class':
                return true;
            case 'remove-class':
                return true;
            case 'set-value':
                return true;
            case 'toggle':
                return true;
            case 'change-text':
                return true;
            case 'change-html':
                return true;
            case 'get-type':
                return true;
            case 'add-validation-group':
                return true;
            case 'remove-validation-group':
                return true;
            case 'show-all':
                return true;
            case 'hide-all':
                return true;
            case 'set-attr':
                return true;
            case 'data-type-number':
                return true;
            case 'data-type-decimal':
                return true;
            default:
                return false;
        }
    };

    if (isExistsMethod(method)) {
        return method;
    } else {
        return null;
    }
};

export const setElementUtil = (elements, parent) => {
    if (typeof parent === 'undefined') {
        parent = null;
    }
    elements.forEach(function (element) {
        let options = {
            element: element,
            event: {},
        };
        $.each(element.attributes, function (index, attr) {
            if (attr.name !== null && attr.name.startsWith("data-prop-")) {
                let spl = attr.name.split("-");
                if (spl[2] !== 'params') {
                    let event = spl[2];
                    let method = checkElementUtil(attr.name);
                    if (method != null) {
                        let prop = {};
                        prop[method] = attr.value.split(",");
                        if (typeof options.event[event] === 'undefined') {
                            options.event[event] = {'name': event, 'prop': []};
                        }
                        options.event[event].prop.push(prop);
                    }
                }
            }
        });
        elementUtil(options, parent);
    });
};

export const getElementByAttr = (whichAttr, whichElement) => {
    let mainElement = $('*');
    if (typeof whichElement !== 'undefined' && typeof whichElement === 'object') {
        mainElement = whichElement;
    }
    if (typeof whichElement !== 'undefined' && typeof whichElement === 'string') {
        mainElement = $(whichElement + " *");
    }
    let elements = [];
    mainElement.filter(function () {
        //for (let attr of this.attributes) {
        let _this = this;
        $.each(this.attributes, function (index, attr) {
            if (attr.name.startsWith(whichAttr)) {
                elements.push(_this);
                return false;
            }
        });
    });
    return elements;
};

export const  _elementUtil = {
    uncheck: function (elements) {
        let e = elementUtil();
        return e.uncheck(elements);
    },
    check: function (elements) {
        let e = elementUtil();
        return e.check(elements);
    },
    disable: function (elements) {
        let e = elementUtil();
        return e.disable(elements);
    },
    enable: function (elements) {
        let e = elementUtil();
        return e.enable(elements);
    },
    show: function (elements) {
        let e = elementUtil();
        return e.show(elements);
    },
    hide: function (elements) {
        let e = elementUtil();
        return e.hide(elements);
    },
    showSlow: function (elements) {
        let e = elementUtil();
        return e.showSlow(elements);
    },
    hideSlow: function (elements) {
        let e = elementUtil();
        return e.hideSlow(elements);
    },
    slideShow: function (elements, callBack) {
        let e = elementUtil();
        return e.slideShow(elements, callBack);
    },
    slideHide: function (elements, callBack) {
        let e = elementUtil();
        return e.slideHide(elements, callBack);
    },
    slideShowSlow: function (elements) {
        let e = elementUtil();
        return e.slideShowSlow(elements);
    },
    slideHideSlow: function (elements) {
        let e = elementUtil();
        return e.slideHideSlow(elements);
    },
    case: function (elements) {
        let e = elementUtil();
        return e.case(elements);
    },
    addValidation: function (elements) {
        let e = elementUtil();
        return e.addValidation(elements);
    },
    removeValidation: function (elements) {
        let e = elementUtil();
        return e.removeValidation(elements);
    },
    addMethodKey: function (elements) {
        let e = elementUtil();
        return e.addMethodKey(elements);
    },
    removeMethodKey: function (elements) {
        let e = elementUtil();
        return e.removeMethodKey(elements);
    },
    filter: function () {

    },
    emptyValue: function (elements) {
        let e = elementUtil();
        return e.emptyValue(elements);
    },
    getService: function (url, params) {
        let e = elementUtil();
        return e.getService(url, params);
    },
    editService: function (url, params) {
        let e = elementUtil();
        return e.editService(url, params);
    },
    postService: function (url, params) {
        let e = elementUtil();
        return e.postService(url, params);
    },
    addClass: function (elements) {
        let e = elementUtil();
        return e.addClass(elements);
    },
    removeClass: function (elements) {
        let e = elementUtil();
        return e.removeClass(elements);
    },
    setValue: function (elements) {
        let e = elementUtil();
        return e.setValue(elements);
    },
    setAttr: function (elements) {
        let e = elementUtil();
        return e.setAttr(elements);
    },
    toggle: function (elements) {
        let e = elementUtil();
        return e.toggle(elements);
    },
    changeText: function (elements) {
        let e = elementUtil();
        return e.changeText(elements);
    },
    changeHtml: function (elements) {
        let e = elementUtil();
        return e.changeHtml(elements);
    },
    getType: function (elements) {
        let e = elementUtil();
        return e.getType(elements);
    },
    addValidationGroup: function (elements) {
        let e = elementUtil();
        return e.addValidationGroup(elements);
    },
    removeValidationGroup: function (elements) {
        let e = elementUtil();
        return e.removeValidationGroup(elements);
    },
    showAll: function (elements) {
        let e = elementUtil();
        return e.showAll(elements);
    },
    hideAll: function (elements) {
        let e = elementUtil();
        return e.hideAll(elements);
    },
    addLoader: function (elements) {
        let e = elementUtil();
        return e.addLoader(elements);
    },
    removeLoader: function (elements) {
        let e = elementUtil();
        return e.removeLoader(elements);
    },
};

export default {_elementUtil}

